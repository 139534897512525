import _ from "lodash";

const errorMessageHandler = (error) => {
  let message = error?.body || error?.message || "Undefined Error";

  if (error.response) {
    const jsonError = error.response.data
    if (jsonError && error.response.status === 400) {
      if (jsonError.type === 'default.form.validation') {
        const violations = jsonError.violations
        message = 'Validation failed with messages:'
        violations.forEach((violation) => {
          message += '\n- ' + violation.property + ': ' + violation.message
        })
      } else if (jsonError.length && jsonError[0].errors && jsonError[0].errors.length) {
        message = jsonError[0].errors[0].message
      } else {
        message = jsonError.title
      }
  } else {
      message = JSON.stringify(jsonError || error.response)
    }
  }

  if ("object" === typeof message) {
    return getFormattedError(message, false);
  }

  if ("string" !== typeof message) {
    return JSON.stringify(error);
  }

  if (512 > message.length) {
    return message;
  }
  const res = /<!--(.*?)-->/gm.exec(message);

  if (_.has(res, 1)) {
    return res[1];
  }

  return message.substring(0, 512) + "..";
};

export const getFormattedError = (body) => {
  if ("object" !== typeof body) {
    return null;
  }

  const fields = ["title", "description", "message"];

  let error = fields
    .filter((item) => body[item])
    .map(
      (item) =>
        body[item] +
        ("." === body[item].substr(body[item].length - 1) ? "\n" : ". \n")
    );

  if (body.violations) {
    body.violations.map((item) =>
      error.push(`${item.property} : ${item.message}\n`)
    );
  }

  return error.join("");
};

export default errorMessageHandler;
