import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  minValue,
  number,
  regex,
  required,
  useInput,
  useNotify,
  useRecordContext,
} from "react-admin";
import { NumberInput, SelectInput, TextInput } from "../app/OutlinedInputs";
import { Button, InputAdornment } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { InfoLink, SectionCard, SectionInfo, SectionInputs } from "../section";
import styled from "styled-components";
import { formatInputTwoDecimals, randomSku } from '../../utils/common'
import { getApiRoute } from '../../config/routes'
import useApi from '../../hooks/useApi'
import errorMessageHandler from '../../Api/errorMessageHandler'
import { VariationImage } from './variation-image'
import { generateId } from '../../utils/uri'
import { useWatch } from "react-hook-form";

const VariationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
`

const CREATE_FORM_TYPE_DEFAULT_STOCK_QUANTITY = 1

export const VariationsInput = ({ source, ...rest }) => {
  const record = useRecordContext()
  const { api } = useApi()
  const watchCategory = useWatch({ name: 'category' })
  const {
    field: { onChange },
  } = useInput({ record, source, ...rest })
  const [variationsObject, setVariationsObject] = useState({})
  const [attributes, setAttributes] = useState([])
  const [attributeTerms, setAttributeTerms] = useState([])
  const [attributeTermsNotFiltered, setAttributeTermsNotFiltered] = useState([])
  const notify = useNotify()
  const [duplicateVariationKey, setDuplicateVariationKey] = useState(null)

  const variationsKeys = useMemo(
    () => Object.keys(variationsObject),
    [variationsObject]
  )

  const choicesMap = useMemo(() => {
    const map = {}
    attributes?.forEach((attribute) => {
      map[attribute.id] = attributeTermsNotFiltered?.find(
        (item) => item.attribute.id === attribute.id
      )?.data
    })

    return map
  }, [attributeTermsNotFiltered, attributes])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const newVariations = Object.values(variationsObject).map((variation) => ({
      stockQty: variation.stockQty,
      sku: !variation.sku ? randomSku(3, 7) : variation.sku.toUpperCase(),
      attributes: attributes
        .filter(({ slug }) => variation[slug])
        .map(({ slug }) => variation[slug]),
      image: variation.image,
      price: variation.price,
      salePrice: variation.salePrice,
    }))
    onChange(newVariations)
  }, [variationsObject])
  /* eslint-enable react-hooks/exhaustive-deps  */

  useEffect(() => {
    if (record?.variations && choicesMap) {
      const newVariationsObj = {}
      record?.variations.forEach((variation) => {
        const formattedVariation = { ...variation }
        delete formattedVariation.attributes
        attributes.forEach((attribute) => {
          const choicesIdSet = new Set(
            choicesMap[attribute.id]?.map(({ id }) => id)
          )

          const selectedItem = variation.attributes?.filter((item) =>
            choicesIdSet.has(item?.id)
          )[0]

          formattedVariation[attribute.slug] = selectedItem
        })
        let id = generateId()
        newVariationsObj[id] = formattedVariation
      })
      setVariationsObject(newVariationsObj)
    } else {
      // Create form
      setVariationsObject({
        [generateId()]: { stockQty: CREATE_FORM_TYPE_DEFAULT_STOCK_QUANTITY },
      })
    }
  }, [attributes, choicesMap, record])

  useEffect(() => {
    const init = async () => {
      try {
        if (watchCategory) {
          const attributesResponse = await api.get(getApiRoute('attributes'))
          const attributeTermsPromises = await (attributesResponse?.data || []).map(async (attribute) => {
            return new Promise(async (resolve, reject) => {

              // category: attribute.slug === 'pa_maten' ? watchCategory?.id || watchCategory : undefined,
              const res = await api.get(getApiRoute('attribute-terms'), { attribute: attribute.id, pageSize: 1000 })
              resolve({ attribute, data: res?.data.data })
            })
          })
          const attributeTermsNotFilteredPromises = await (attributesResponse?.data || []).map(async (attribute) => {
            return new Promise(async (resolve, reject) => {
              const res = await api.get(getApiRoute('attribute-terms'), { attribute: attribute.id, pageSize: 1000 })
              resolve({ attribute, data: res?.data.data })
            })
          })

          const attributeTermsResponse = await Promise.all(attributeTermsPromises)
          const attributeTermsNotFilteredResponse = await Promise.all(attributeTermsNotFilteredPromises)
          setAttributes(attributesResponse?.data || [])
          setAttributeTerms(attributeTermsResponse || [])
          setAttributeTermsNotFiltered(attributeTermsNotFilteredResponse || [])
        }
      } catch (e) {
        notify(errorMessageHandler(e), 'error', {}, false, 100000)
      }
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCategory, record?.variations])

  const hasMatchingOptions = useCallback((data) => {
    const seen = {};

    for (const key in data) {
      const { pa_kleur, pa_maten } = data[key];
      const combinedKey = `${pa_kleur?.id || pa_kleur}-${pa_maten?.id || pa_maten}`;

      if (seen[combinedKey]) {
        setDuplicateVariationKey(key)
        return true;
      }
  
      seen[combinedKey] = true;
    }
    setDuplicateVariationKey(null)
    return false;
  }, [])

  useEffect(() => {
    hasMatchingOptions(variationsObject)
  }, [variationsObject, hasMatchingOptions])

  const handleValueChange = useCallback((ev, variationId, source) => {
    setVariationsObject((cur) => {
      const variation = cur[variationId] || {}
      return {
        ...cur,
        [variationId]: {
          ...variation,
          [source]:
            source === 'sku' ? ev.target.value.toUpperCase() : ev.target.value,
        },
      }
    })
  }, [])

  return (
    <SectionCard>
      <SectionInfo title="Add product attributes and quantity">
        <span>
          Add your unique SKU code or product identifier for this option
        </span>
        <InfoLink
          href="https://www.swanmarket.nl/support-voor-creatieven/"
          target="_blank"
        >
          Learn more
        </InfoLink>
      </SectionInfo>
      <SectionInputs>
        {Object.keys(variationsObject).map((variationId, variationIndex) => {
          const variation = variationsObject[variationId]
          if (!variation) return null

          return (
            <VariationWrapper key={`variation-${variationId}`}>
              {variationsKeys.length > 1 && (
                <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={(e) => {
                    e.preventDefault()
                    setVariationsObject((cur) => {
                      const newVariations = { ...cur }
                      delete newVariations[variationId]
                      return newVariations
                    })
                  }}
                >
                  <DeleteIcon />
                </Button>
              )}
              {attributes?.map((attribute, attributeIndex) => {
                const finded = attributeTerms?.find(
                  (item) => item.attribute.id === attribute.id
                )?.data

                const choices = finded ? [...finded] : []
                

                if (!choices) {
                  return null
                }

                if (variation && variation[attribute.slug] && !choices.find(item => item.id === variation[attribute.slug].id)) {
                  if (typeof variation[attribute.slug] === 'object') {
                    choices.push(variation[attribute.slug])
                  }
                }

                return (
                  <SelectInput
                    key={`variation-attribute-${attributeIndex}`}
                    source={`_${variationId}_${attribute.slug}`}
                    label={attribute.name}
                    choices={choices}
                    defaultValue={variation[attribute.slug] ? variation[attribute.slug].id : ''}
                    onChange={(e) =>
                      handleValueChange(e, variationId, attribute.slug)
                    }
                  />
                )
              })}
              {duplicateVariationKey === variationId && <div style={{ color: '#FF5733', marginBottom: '20px' }}>The values for <b>Kleur</b> and <b>Maten</b> in this variation are the same as in another variation.</div>}
              <TextInput
                source={`_${variationId}_sku`}
                label="SKU (Only use letters and numbers. You can use - as a separator. eg: SKU-123456)"
                defaultValue={
                  typeof variation.sku === 'undefined' || variation.sku === null
                    ? randomSku(3, 7)
                    : variation.sku.toUpperCase()
                }
                validate={regex(
                  /^[A-Z0-9-]*$/,
                  'Must be a valid SKU Code (eg: SKU-123456)'
                )}
                format={(value) => value && value.toUpperCase()}
                parse={(value) => value && value.toUpperCase()}
                onChange={(e) => handleValueChange(e, variationId, 'sku')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {variation.skuPrefix ? variation.skuPrefix + '_' : ''}
                    </InputAdornment>
                  ),
                }}
              />
              <NumberInput
                source={`_${variationId}_stockQty`}
                label="Stock quantity"
                validate={[required(), number(), minValue(0)]}
                defaultValue={variation?.stockQty}
                onChange={(e) => handleValueChange(e, variationId, 'stockQty')}
              />

              {variationsKeys.length < 2 ? null : (
                <NumberInput
                  source={`_${variationId}_price`}
                  label="Price"
                  defaultValue={variation?.price}
                  onChange={(e) => handleValueChange(e, variationId, 'price')}
                  type="text"
                  format={formatInputTwoDecimals}
                  parse={(v) => v}
                />
              )}
              {variationsKeys.length < 2 ? null : (
                <NumberInput
                  source={`_${variationId}_salePrice`}
                  label="Sale price"
                  defaultValue={variation?.salePrice}
                  onChange={(e) =>
                    handleValueChange(e, variationId, 'salePrice')
                  }
                  type="text"
                  format={formatInputTwoDecimals}
                  parse={(v) => v}
                />
              )}
              {variationsKeys.length < 2 ? null : (
                <VariationImage
                  setVariationsObject={setVariationsObject}
                  variationId={variationId}
                  image={variation.image}
                />
              )}
              <Separator />
            </VariationWrapper>
          )
        })}

        <span>
          If you have same product in a different size or color, add another
          option.
        </span>
        <span>
          If you have a variation in something else than color or size, please
          create multiple products.
        </span>

        <Button
          onClick={(e) => {
            e.preventDefault()
            setVariationsObject((cur) => ({
              ...cur,
              [generateId()]: {
                skuPrefix: variationsObject[variationsKeys[0]].skuPrefix,
                stockQty: CREATE_FORM_TYPE_DEFAULT_STOCK_QUANTITY,
              },
            }))
          }}
          style={{ margin: '20px 0' }}
          variant="contained"
          color="primary"
        >
          add another option
        </Button>
      </SectionInputs>
    </SectionCard>
  )
}
