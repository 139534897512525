import React, { useState, useEffect, useMemo } from "react";
import { useInput, required, useRecordContext, useNotify } from "react-admin";
import { SelectInput } from "../app/OutlinedInputs";
import { SectionCard, SectionInfo, SectionInputs } from "../section";
import { categoryTransformer } from "../../transformers/category";
import useApi from "../../hooks/useApi";
import { getApiRoute } from "../../config/routes";
import errorMessageHandler from "../../Api/errorMessageHandler";

export const CategoryInput = ({ source, validate, ...rest }) => {
  const notify = useNotify();
  const record = useRecordContext();
  const { api } = useApi();

  const {
    field: { onChange },
  } = useInput({ record, source, ...rest });
  const [mounted, setMounted] = useState(false);
  const [category, setCategory] = useState(record?.category || []);

  const [categories, setCategories] = useState([]);
  const [mainCategory, setMainCategory] = useState(null);
  const [subcategory1, setSubcategory1] = useState(null);
  const [subcategory2, setSubcategory2] = useState(null);

  const mainCategories = useMemo(
    () => categories.filter((item) => !item.parent).map(item => ({ ...item, disabled: item.id === 15 })),
    [categories]
  );

  const subCategories1 = useMemo(
    () =>
      mainCategory
        ? categories.filter((item) => item.parent?.id === mainCategory)
        : [],
    [mainCategory, categories]
  );

  const subCategories2 = useMemo(
    () =>
      mainCategory && subcategory1
        ? categories.filter((item) => item.parent?.id === subcategory1)
        : [],
    [mainCategory, subcategory1, categories]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onChange(category);
  }, [category]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (mounted || !categories.length) return;

    setMounted(true);

    if (record?.category) {
      const categoryId = record.category.id;
      const selectedItem = categories.filter(
        (item) => item.id === categoryId
      )[0];

      const ancestors = [];
      const handlers = [setSubcategory2, setSubcategory1, setMainCategory];
      let node = selectedItem;

      while (node) {
        ancestors.push(node);
        node = node.parent;
      }

      while (ancestors.length) {
        const item = ancestors.pop();
        const handler = handlers.pop();
        handler(item.id);
      }
    }
  }, [record, categories, mounted]);

  useEffect(() => {
    setCategory(subcategory2 || subcategory1 || mainCategory);
  }, [mainCategory, subcategory1, subcategory2]);

  useEffect(() => {
    if (!mounted) return;
    if (!subCategories1.find((item) => item.id === subcategory1)) {
      setSubcategory1(undefined);
    }
    if (!subCategories2.find((item) => item.id === subcategory2)) {
      setSubcategory2(undefined);
    }
  }, [subCategories1, subCategories2, subcategory1, subcategory2, mounted]);

  useEffect(() => {
    api
      .get(getApiRoute("categories"))
      .then((res) => {
        setCategories(categoryTransformer(res?.data || []));
      })
      .catch((e) => {
        notify(errorMessageHandler(e), "error", {}, false, 100000);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard>
      <SectionInfo title={"Help customers find your product"} />
      <SectionInputs>
        {mounted && (
          <SelectInput
            source="_mainCategory"
            label="Category"
            disabled={!categories.length}
            choices={mainCategories}
            defaultValue={mainCategory}
            onChange={(e) => {
              setMainCategory(e.target.value);
            }}
            validate={validate}
          />
        )}

        {!!subCategories1.length && (
          <SelectInput
            validate={required()}
            source="_subCategory1"
            label="Subcategory"
            choices={subCategories1}
            defaultValue={subcategory1}
            onChange={(e) => {
              setSubcategory1(e.target.value);
            }}
          />
        )}
        {!!subCategories2.length && (
          <SelectInput
            validate={required()}
            source="_subCategory2"
            label="Subcategory 2"
            choices={subCategories2}
            defaultValue={subcategory2}
            onChange={(e) => {
              setSubcategory2(e.target.value);
            }}
          />
        )}
      </SectionInputs>
    </SectionCard>
  );
};
