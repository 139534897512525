import IBAN from "iban";

export const ROLES = [
  { label: "Admin", value: "ROLE_ADMIN" },
  { label: "Creative", value: "ROLE_CREATIVE" },
];

export const LOGS_TABLE_HEADER = [
  {
    field: "channel",
    headerName: "Channel",
    flex: 1,
    sortable: false,
  },
  {
    field: "code",
    headerName: "Code",
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '100%' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "message",
    headerName: "Message",
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '100%' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      return formatUTCDate(row.createdAt);
    },
  },
];

export const formatTimeFromDate = (date) => {
  if (!(date instanceof Date)) {
    return date;
}

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
}

export const formatDate = (
  datetime,
  locales = "nl-Nl",
  options = {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
  }
) => {
  const date = "string" === typeof datetime ? new Date(datetime) : datetime;

  return date.toLocaleDateString(locales, options);
};

export const formatUTCDate = (
    datetime
) => {
  if (!datetime) {
    return null;
  }
  let d = new Date(datetime),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
};

export const randomSku = (letterLength = 3, numberLength = 5) => {
  let letterResult = "";
  let numberResult = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXY";
  const numbers = "0123456789";
  const charLength = characters.length;
  const numLength = numbers.length;

  for (let i = 0; i < letterLength; i++) {
    letterResult += characters.charAt(Math.floor(Math.random() * charLength));
  }

  for (let i = 0; i < numberLength; i++) {
    numberResult += numbers.charAt(Math.floor(Math.random() * numLength));
  }

  return `${letterResult}${numberResult}`.toUpperCase();
};

export const validateIBAN = (iban) => {
  if (iban && !IBAN.isValid(iban)) {
      return 'IBAN number is not valid';
  }

  return undefined;
}

export const transformWhatsappLink = (whatsappLink) => {
  const res = whatsappLink?.toString()?.match(/\d+/g)
  return res
}

export const formatInputTwoDecimals = (v) => {
  let result = null

  if (!v) {
    return ''
  }

  let parsedValue = v.toString().replace('.', ',')

  const decimalIndex = parsedValue.indexOf(',')

  if (decimalIndex === -1) {
    result = `${parsedValue},00`
  } else {
    result = parsedValue
      .slice(0, decimalIndex + 3)
      .padEnd(decimalIndex + 3, '0')
  }

  return result
}
