import BookIcon from "@mui/icons-material/Book";
import ProductCreate from "./ProductCreate";
import ProductEdit from "./ProductEdit";
import ProductList from "./ProductList";

const products = {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  icon: BookIcon,
};

export default products;
