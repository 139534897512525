import React, { useCallback } from "react";
import { useRefresh } from "react-admin";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import useApi from "../../hooks/useApi";

const ImportButton = React.memo(({ resource, text, accept = [".xlsx"], ...props }) => {
  const { api } = useApi();
  const refresh = useRefresh();

  const onImportChange = useCallback(
    async (e) => {
      let formData = new FormData();

      formData.append("file", e.target.files[0]);
      e.target.value = "";

      await api.importCsv(resource, formData).then(refresh);
    },
    [api, resource, refresh]
  );

  if (!resource) {
    return null;
  }

  return (
    <Button color="primary" component="label" startIcon={<CloudUploadIcon />} {...props}>
      <span sx={{ marginLeft: 1 }}>{text || `Import`}</span>
      <input
        type="file"
        accept={accept.join(" ")}
        onChange={onImportChange}
        style={{ display: "none" }}
      />
    </Button>
  );
});

export default ImportButton;
