import { AppBar, Layout, Sidebar, CheckForApplicationUpdate } from "react-admin";
import styled from "styled-components";
import Notification from "./Notification";
import { MyMenu } from "./MyMenu";
import { CssBaseline } from "@mui/material";
// import { useCallback, useEffect, useState } from 'react'
// import { checkNewVersion } from "../../utils/newVersionAvailable";
const AppBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
`;

const CustomAppBar = (props) => {
  return <AppBar {...props} container={AppBarContainer} />;
};

const SidebarContainer = styled.div`
  background: white;
  & .RaSidebar-fixed {
    width: 100%;
  }
`;

const CustomSidebar = (props) => {
  return (
    <SidebarContainer>
      <Sidebar {...props} />
    </SidebarContainer>
  );
};

const AUTO_UPDATE_INTERVAL = 300000

const CustomLayout = (props) => {
  // const [currentHash, setCurrentHash] = useState('')
  // const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false)

  // const checkNewVersionCB = useCallback(async () => {
  //   const res = await checkNewVersion(currentHash, setCurrentHash)
  //   setIsNewVersionAvailable(res)
  // }, [currentHash])

  // useEffect(() => {
  //   checkNewVersionCB()
  //   const interval = setInterval(() => {
  //     checkNewVersionCB()
  //   }, AUTO_UPDATE_INTERVAL)

  //   return () => clearInterval(interval);
  // }, [checkNewVersionCB])

  // const reloadPage = useCallback((e) => {
  //   e.preventDefault();
  //   window.location.reload()
  // }, [])

  return (
    <>
      {/* { isNewVersionAvailable &&
        <Alert severity="info" style={{ position: 'fixed', bottom: '0px', left: '50%', zIndex: '9999', transform: 'translate(-50%, -50%)' }}>
          New version is available, please <a href="#" onClick={reloadPage}>reload page</a>
        </Alert>
      } */}
      <CssBaseline />
      <Layout
        {...props}
        menu={MyMenu}
        appBar={CustomAppBar}
        sidebar={CustomSidebar}
        notification={Notification}
      />
      <CheckForApplicationUpdate
        interval={AUTO_UPDATE_INTERVAL}
        fetchOptions={{
          cache: 'no-cache',
        }}
      />
    </>
  )
}

export default CustomLayout;
