import {
    RichTextInput,
    RichTextInputToolbar,
    ListButtons,
    useTiptapEditor,
    DefaultEditorOptions
} from 'ra-input-rich-text';
import { ToggleButton as MUIToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import HtmlIcon from '@mui/icons-material/Html';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import { useCallback, useEffect, useState, memo, useMemo } from 'react';
import { useAuthProvider } from 'react-admin'
import { CustomPasteExtension } from './paste-hanlder';
import Placeholder from '@tiptap/extension-placeholder'

// export const CustomEditorOptions = 

const codeStyle = {
    background: '#e9ecef',
    padding: '10px',
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
    width: '100%',
    margin: '0 0 20px 0'
}

const ToggleButton = styled(MUIToggleButton)({
    marginLeft: '0 !important',
    marginRight: '0 !important',
})

export const CustomRichTextInputToolbar = memo(({ setHtmlShow, setHtml, isAdmin = false, size, ...props }) => {
    const editor = useTiptapEditor();
    
    const [actives, setActives] = useState(prev => ({
        bold: false,
        italic: false,
        heading2: false,
        heading3: false,
        html: false
    }))

    const update = useCallback(() => {
        setActives(prev => ({
            bold: editor.isActive('bold'),
            italic: editor.isActive('italic'),
            heading2: editor.isActive('heading', { level: 2 }),
            heading3: editor.isActive('heading', { level: 3 }),
            html: prev.html
        }))

    }, [editor])

    useEffect(() => {
        if (editor) {
            editor.on('update', ({ editor }) => {
                setHtml(editor.getHTML())
            })
        
            editor.on('selectionUpdate', ({ editor }) => {
                update()
            })

            editor.on('create', ({ editor }) => {
                setHtml(editor.getHTML())
            })
        }
    }, [editor, setHtml, update])

    const toggleBold = useCallback(() => {
        setActives((prev) => ({ ...prev, bold: !editor.isActive('bold') }))
        editor.chain().toggleBold().focus().run()
    }, [editor, setActives])

    const toggleItalic = useCallback(() => {
        setActives((prev) => ({ ...prev, italic: !editor.isActive('italic') }))
        editor.chain().focus().toggleItalic().run()
    }, [editor, setActives])

    /*const toggleHeading2 = useCallback(() => {
        setActives((prev) => ({ ...prev, heading3: false, heading2: !editor.isActive('heading', { level: 2 })}))
        editor.chain().focus().toggleHeading({ level: 2 }).run()
    }, [editor, setActives])*/

    const toggleHeading3 = useCallback(() => {
        setActives((prev) => ({ ...prev, heading2: false, heading3: !editor.isActive('heading', { level: 3 })}))
        editor.chain().focus().toggleHeading({ level: 3 }).run()
    }, [editor, setActives])

    const setBR = useCallback(() => {
        editor.chain().focus().setHardBreak().run()
    }, [editor])

    const toggleHTML = useCallback(() => {
        setActives((prev) => ({ ...prev, html: !actives.html }))
        setHtmlShow(!actives.html)
    }, [actives, setActives, setHtmlShow])


    if(!editor) {
        return null
    }

    return (
        <RichTextInputToolbar {...props}>
            <ToggleButton
                aria-label="Bold (<strong>)"
                title="Bold (<strong>)"
                onClick={toggleBold}
                selected={actives.bold}
                sx={{ marginRight: '8px !important', width: '35px', height: '35px' }}
                value={actives.bold}
            >
                <FormatBoldIcon fontSize="inherit" />
            </ToggleButton>
            <ToggleButton
                aria-label="Italic (<em>)"
                title="Italic (<em>)"
                onClick={toggleItalic}
                selected={actives.italic}
                sx={{ marginRight: '8px !important', width: '35px', height: '35px' }}
                value={actives.italic}
            >
                <FormatItalicIcon fontSize="inherit" />
            </ToggleButton>
            {/* <ToggleButton
                aria-label="Heading 2 (<h2>)"
                title="Heading 2 (<h2>)"
                onClick={toggleHeading2}
                selected={actives.heading2}
                style={{ fontSize: '18px', lineHeight: '24px'}}
                value={actives.heading2}
            >
                H2
            </ToggleButton> */}
            <ToggleButton
                aria-label="Heading 3 (<h3>)"
                title="Heading 3 (<h3>)"
                onClick={toggleHeading3}
                selected={actives.heading3}
                style={{ fontSize: '18px', lineHeight: '24px'}}
                sx={{ marginRight: '8px !important', width: '35px', height: '35px' }}
                value={actives.heading3}
            >
                H
            </ToggleButton>
            <ToggleButton
                aria-label="Break (<br>)"
                title="Break (<br>)"
                onClick={setBR}
                sx={{ marginRight: '8px !important', width: '35px', height: '35px' }}
                value={''}
            >
                <SpaceBarIcon fontSize="inherit" />
            </ToggleButton>
            <ListButtons size={size} />
            {isAdmin &&
            <ToggleButton
                aria-label="HTML source (read only)"
                title="HTML source (read only)"
                onClick={toggleHTML}
                selected={actives.html}
                value={actives.html}
                sx={{ width: '35px', height: '35px' }}
            >
                <HtmlIcon fontSize="inherit" />
            </ToggleButton>
            }
        </RichTextInputToolbar>
    )
})

export const CustomRichTextInput = memo(({ size, placeholder, ...props }) => {
    const [html, setHtml] = useState(null)
    const [htmlShow, setHtmlShow] = useState(null)
    const [isAdmin, setIsAdmin] = useState(null)
    const authProvider = useAuthProvider();

    const options = useMemo(() => {
        return {
            ...DefaultEditorOptions,
            extensions: [
                ...DefaultEditorOptions.extensions,
                CustomPasteExtension,
                Placeholder.configure({
                  placeholder: placeholder,
                  emptyEditorClass: 'is-editor-empty'
                }),
            ],
        }
    }, [placeholder])

    const init = useCallback(async () => {
        const roles = await authProvider.getPermissions();
        setIsAdmin(roles.includes('ROLE_ADMIN'))
    }, [authProvider])
    
    useEffect(() => {
        init() 
    }, [init])

    return (
        <div>
            <RichTextInput
                editorOptions={options}
                fullWidth
                sx={{
                  [`& .tiptap`]: {
                    minHeight: '5em',
                  },
                  '.RaLabeled-label': {
                    fontSize: '14px !important'
                  }
                }}
                toolbar={<CustomRichTextInputToolbar size={size} isAdmin={isAdmin} setHtml={setHtml} setHtmlShow={setHtmlShow} />}
                {...props}
            />
            {htmlShow && isAdmin &&
                <pre style={codeStyle}>{html}</pre>
            }
        </div>
    )
})
