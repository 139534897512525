import React, { useState, useCallback, useEffect } from "react";
import useApi from "../../hooks/useApi";
import { formatUTCDate } from "../../utils/common";
import {
  BooleanInput,
  DateInput,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  Toolbar, useNotify, useUpdate,
  CheckboxGroupInput,
  Confirm,
  FormDataConsumer
} from "react-admin";
import { SectionCard, SectionInfo, SectionInputs } from "../section";
import { TextInput, NumberInput } from '../app/OutlinedInputs'
import styled from "styled-components";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import { getApiRoute } from "../../config/routes";
import { VatInput } from "../vat-input";
import withTitle from '../with-title'
import { validateIBAN, transformWhatsappLink } from '../../utils/common'
import { CountryInput } from "../country-input";
import { useForm } from "react-hook-form"
import { CardComponent } from "../edit-component";

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: scale-down;
`;

const Images = styled.div`
  display: flex;
  gap: 8px;
  margin: 12px 0;
  overflow-x: auto;
`;

const ImageWrapper = styled.div`
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(60, 60, 60);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 0.3;
    }

    & > div {
      opacity: 1;
    }
  }
`;

const SettingsList = (props) => {
  const { api } = useApi();
  const [creative, setCreative] = useState(undefined);
  const [update] = useUpdate();
  const notify = useNotify();
  const [stores, setStores] = useState([])
  const [events, setEvents] = useState([])
  const [vacationDialog, setVacationDialog] = useState(false);
  const [vacationDays, setVacationDays] = useState(null)
  const [isKor, setIsKor] = useState(false)
  const [isOutsideNl, setIsOutsideNl] = useState(true)
  const { reset } = useForm();

  const onChangeKor = useCallback((e) => {
    setIsKor(e.target.checked)
  }, [])

  const onChangeCountry = useCallback((e) => {
    setIsOutsideNl(!['nl'].includes(e.target.value.toLowerCase()))
  }, [])

  const fetchCreative = useCallback(async () => {
    try {
      const creative = (await api.getLoggedinUserCreative())?.data;
      creative.onVacationFrom = formatUTCDate(creative.onVacationFrom);
      creative.onVacationUntil = formatUTCDate(creative.onVacationUntil);
      setCreative(creative);
      setIsKor(creative.kor)
      setIsOutsideNl(!['nl'].includes(creative.country?.id?.toLowerCase()))
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const validateVacationUntil = (value, allValues) => {
    if (allValues.onVacationFrom && !value) {
      return 'You must also select a "Until" date';
    }

    if (allValues.onVacationFrom && value) {
      if (new Date(allValues.onVacationFrom) > new Date(value)) {
        return '"Until" date should be after "From" date';
      }
    }

    return undefined;
  }

  const transform = (data) => {
    delete data.name;
    delete data.description;
    delete data.metaDescription;
    delete data.image;
    delete data.images;
    delete data.user
    data.onVacationFrom = data.onVacationFrom
      ? new Date(data.onVacationFrom)
      : null;
    data.onVacationUntil = data.onVacationUntil
      ? new Date(data.onVacationUntil)
      : null;

    return data;
  };

  useEffect(() => {
    let mount = true;
    api.get(getApiRoute('creative-stores')).then(({ data }) => {
      setStores(data.data)
    })

    api.get(getApiRoute('creative-events')).then(({ data }) => {
      setEvents(data.data)
    })

    if (mount) {
      fetchCreative();
    }

    return () => (mount = false);
  }, [fetchCreative, api]);

  const CustomToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton size="medium" />
      </Toolbar>
    );
  };

  const save = async (values) => {
    // check vacation > 30 days
    const dateFromObj = new Date(values.onVacationFrom);
    const dateUntilObj = new Date(values.onVacationUntil);
    
    const diff = dateUntilObj - dateFromObj;
    
    const diffDays = diff / (1000 * 60 * 60 * 24);
    
    if (diffDays > 30) {
      setVacationDays(diffDays)
      setVacationDialog(true)
    } else {
      await saveFunction(values)
    }
  };

  const saveFunction = useCallback(
      async (values) => {
        try {
          const whatsappNumber = transformWhatsappLink(values.whatsappLink);
          const data = {
            ...transform(values),
            whatsappLink: whatsappNumber?.length ? `wa.me/${transformWhatsappLink(whatsappNumber[0])}` : null
          };

          await update(
            "creatives",
            {
              id: values.id,
              data,
            },
            {
              returnPromise: true,
              onSuccess: () => {
                notify("Changes saved", "success");
              },
            }
          );
          setCreative(null)
          fetchCreative()
        } catch (error) {
          notify(errorMessageHandler(error), "danger");
          reset(values)
        }
      },
      [notify, update, fetchCreative, reset]
  );

  const handleDialogClose = () => setVacationDialog(false)
  const handleConfirm = (values) => {
    saveFunction(values);
    setVacationDialog(false)
  }


  const onChangeShowOnSite = useCallback((e) => {
    if (e.target.checked && !creative.member) {
      notify("Alleen de social links van Swan leden worden alleen op de website getoond.", "info");
    }
  }, [notify, creative])

  if (!creative) {
    return <div></div>;
  }

  const EventOption = (record) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid gray', borderRadius: 10, padding: '5px', margin: '5px' }}>
        <b>{record.name}</b>
        <span style={{ fontSize: '14px', color: 'gray' }}>{record.takePlaceOn.formatted6}, {record.time.from} - {record.time.to}</span>
        <span style={{ fontSize: '14px', color: 'gray' }}>{record.location}</span>
      </div>
    )
  }

  return (
    <div>
      <Edit
        {...props}
        id={creative.id}
        resource={'creatives'}
        transform={transform}
        component={CardComponent}
        redirect={false}
      >
        <SimpleForm sx={{ padding: 0, width: '100%' }} toolbar={<CustomToolbar />} onSubmit={save}>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <>
                <SectionCard>
                  <SectionInfo title={'Basic info'}>
                    <span>
                      If you want to change this data, please send email to{' '}
                      <b>online@swanmarket.nl</b>
                    </span>
                  </SectionInfo>
                  <SectionInputs>
                    <TextInput
                      label="Name"
                      source="name"
                      validate={required()}
                      disabled={true}
                    />
                    <TextInput label="Slug" source="slug" disabled={true} />
                    <RichTextInput
                      toolbar={<RichTextInputToolbar children={[]} />}
                      source="description"
                      name="description"
                      readOnly={true}
                      fullWidth={true}
                    />
                    <TextInput
                      multiline
                      label="Meta description"
                      source="metaDescription"
                      disabled={true}
                    />
                                        <div
                      style={{
                        display: creative.images?.length > 0 ? 'block' : 'none',
                      }}
                    >
                      <p style={{ color: 'gray' }}>Gallery</p>
                      <Images
                        className="images-holder"
                        style={{ marginTop: '0' }}
                      >
                        {creative.images?.map((img) => (
                          <ImageWrapper>
                            <StyledImage src={img.absoluteUrl} alt={img.id} />
                          </ImageWrapper>
                        ))}
                      </Images>
                    </div>
                    <div style={{ display: creative.image ? 'block' : 'none' }}>
                      <p style={{ color: 'gray' }}>Thumbnail</p>
                      <Images
                        className="images-holder"
                        style={{ marginTop: '0' }}
                      >
                        <ImageWrapper>
                          <StyledImage
                            src={creative.image?.absoluteUrl}
                            alt={creative.image?.id}
                          />
                        </ImageWrapper>
                      </Images>
                    </div>
                    {!creative ? null : (
                      <VatInput
                        isEdit
                        isKor={isKor}
                        isOutsideNl={isOutsideNl}
                        source="vat"
                        validate={required()}
                      />
                    )}
                    <BooleanInput
                      source="kor"
                      name="kor"
                      label="KOR"
                      onChange={onChangeKor}
                    />
                  </SectionInputs>
                </SectionCard>
                <SectionCard>
                  <SectionInfo title={'Other settings'}>
                    <span>
                      The email and phone number won't be shared to anyone, but
                      social media links can be published
                    </span>
                  </SectionInfo>
                  <SectionInputs>
                    {/* <TextInput
                  label="Email"
                  source="user.email"
                  validate={required()}
              /> */}
                    <TextInput
                      autoFocus
                      label="Official name"
                      source="officialName"
                    />
                    <TextInput label="Street address" source="streetAddress" />
                    <TextInput label="Postal code" source="postalCode" />
                    <TextInput label="City" source="city" />
                    <CountryInput source="country" onChange={onChangeCountry} />
                    <TextInput
                      label="KVK number (Chamber of Commerce)"
                      source="kvkNumber"
                    />
                    <TextInput label="BTW number" source="btwNumber" />
                    <TextInput
                      label="IBAN"
                      source="iban"
                      validate={validateIBAN}
                    />
                    {formData.iban &&
                      formData.iban.length &&
                      !formData.iban.includes('NL') && (
                        <TextInput
                          label="BIC"
                          source="bic"
                          validate={required()}
                        />
                      )}
                    <TextInput
                      label="Bank account holder (tenaamstelling)"
                      source="bankAccountHolder"
                    />

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Website URL" source="url" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showUrl"
                        name="showUrl"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Public email" source="publicEmail" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showPublicEmail"
                        name="showPublicEmail"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Facebook link" source="facebookLink" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showFacebookLink"
                        name="showFacebookLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput
                        label="Instagram link"
                        source="instagramLink"
                      />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showInstagramLink"
                        name="showInstagramLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput
                        label="Pinterest link"
                        source="pinterestLink"
                      />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showPinterestLink"
                        name="showPinterestLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Twitter link" source="twitterLink" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showTwitterLink"
                        name="showTwitterLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Tiktok" source="tiktokLink" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showTiktokLink"
                        name="showTiktokLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Snapchat" source="snapchatLink" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showSnapchatLink"
                        name="showSnapchatLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Mastodon" source="mastodonLink" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showMastodonLink"
                        name="showMastodonLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '15px',
                      }}
                    >
                      <NumberInput
                        label="WhatsApp"
                        source="whatsappLink"
                        placeholder="31612345678"
                        helperText="Fill phone with country code"
                        format={transformWhatsappLink}
                        InputLabelProps={{ shrink: true }}
                      />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showWhatsappLink"
                        name="showWhatsappLink"
                        onChange={onChangeShowOnSite}
                      />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput label="Phone number" source="phoneNumber" />
                      <span style={{ marginLeft: '5px' }}></span>
                      <BooleanInput
                        label="Show on site"
                        source="showPhoneNumber"
                        name="showPhoneNumber"
                        onChange={onChangeShowOnSite}
                      />
                    </div>
                  </SectionInputs>
                </SectionCard>
                {Boolean(stores.length) && (
                  <SectionCard>
                    <SectionInfo title={'Stores'}>
                      My products are available in the following Swan stores:
                    </SectionInfo>
                    <SectionInputs>
                      <CheckboxGroupInput
                        source="stores"
                        optionValue="id"
                        optionText="name"
                        choices={stores}
                        row={false}
                      />
                    </SectionInputs>
                  </SectionCard>
                )}
                {Boolean(events.length) && (
                  <SectionCard>
                    <SectionInfo title={'Events'}>
                      Show our site visitors on which Swan markets you have a
                      stand. For them a good chance for a meet and greet, and
                      you will sell more. For booking a stand, please visit
                      <br />
                      <a
                        href="https://www.theofflinecompany.nl/meedoen/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.theofflinecompany.nl/meedoen/
                      </a>
                    </SectionInfo>
                    <SectionInputs>
                      <CheckboxGroupInput
                        source="events"
                        optionValue="id"
                        optionText={EventOption}
                        choices={events}
                        row={false}
                      />
                    </SectionInputs>
                  </SectionCard>
                )}
                <SectionCard>
                  <SectionInfo title={'Vacation details'}></SectionInfo>
                  <Confirm
                    isOpen={vacationDialog}
                    title={`Are you sure you are on holiday for ${vacationDays} days?`}
                    onConfirm={() => {
                      handleConfirm(formData)
                    }}
                    onClose={handleDialogClose}
                  />
                  <DateInput
                    variant="outlined"
                    source="onVacationFrom"
                    name="onVacationFrom"
                  />
                  <DateInput
                    variant="outlined"
                    source="onVacationUntil"
                    name="onVacationUntil"
                    validate={[validateVacationUntil]}
                  />
                </SectionCard>
              </>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </div>
  )
};

export default withTitle(SettingsList, 'Settings List');
