export const sources = {
  // For ROLE_CREATIVE
  "creatives-offline.swan.local:3000": "http://backend-creative.swan.local", // If midlayer is running on your host (docker)
  "creatives-tst.swan.local:3000": "https://backend-creative-tst.swan.market", // to connect to tst midlayer
  // For ROLE_ADMIN
  "admin-offline.swan.local:3000": "http://backend-admin.swan.local", // If midlayer is running on your host (docker)
  "admin-tst.swan.local:3000": "https://backend-admin-tst.swan.market", // to connect to tst midlayer
  //
  "admin-tst.swan.market": "https://backend-admin-tst.swan.market",
  "admin.swan.market": "https://backend-admin.swan.market",
  "creatives-tst.swan.market": "https://backend-creative-tst.swan.market",
  "creatives.swan.market": "https://backend-creative.swan.market",
};

export const BASE_URL = sources[window.location.host] || window.location.host;

export const getApiRoute = (name, params = {}) => {
  let route = name;

  // Interpolate route with provided params
  for (const key in params) {
    route = route.replace(`:${key}`, params[key]);
  }

  return `${BASE_URL}/api/${route}`;
};
