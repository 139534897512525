import axios from "axios";
import { getApiRoute } from "../config/routes";
import _ from "lodash";
import errorMessageHandler from "./errorMessageHandler";

class Api {
  notify;
  translate;
  setIsSubmitting;
  headers = {};

  bag = { get: {}, create: {}, update: {} };

  constructor(notify = () => {}, translate, setIsSubmitting = () => {}) {
    this.notify = notify;
    this.translate = translate;
    this.setIsSubmitting = setIsSubmitting;
  }

  api = (method) => _.get(this.bag, method, this.up(method));

  up = (method) => {
    let config = {
      headers: this.getHeaders(method),
      withCredentials: true,
    };

    const api = axios.create(config);

    this.bag[method] = api;

    return api;
  };

  getHeaders = (method) => {
    let headers = {
      accept: "application/json",
    };

    if (["patch", "put"].includes(method)) {
      headers["content-type"] = "application/merge-patch+json";
    } else {
      headers["content-type"] = "application/ld";
    }

    return headers;
  };

  post = (url, body, successMessage, errorMessage) => {
    this.setIsSubmitting(true);

    return this.api("post")
      .post(url, body || {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, "error");

          throw new Error(response.statusText);
        }

        if (successMessage) {
          this.notify(successMessage);
        }

        this.setIsSubmitting(false);

        return response;
      })
      .catch((error) => {
        if (errorMessage === "prevent") {
          throw error;
        }
        this.notify(
          errorMessage || errorMessageHandler(error),
          "error",
          {},
          false,
          10000
        );
        this.setIsSubmitting(false);

        throw error;
      });
  };

  patch = (url, body, successMessage) => {
    this.setIsSubmitting(true);

    return this.api("patch")
      .patch(url, body)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, "error");

          throw new Error(response.statusText);
        }

        if (successMessage) {
          this.notify(successMessage);
        }

        this.setIsSubmitting(false);

        return response;
      })
      .catch((error) => {
        this.notify(errorMessageHandler(error), "error", {}, false, 10000);
        this.setIsSubmitting(false);

        throw error;
      });
  };

  put = (url, body, successMessage, errorMessage) => {
    this.setIsSubmitting && this.setIsSubmitting(true);

    return this.api("put")
      .put(url, body || {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, "error");
          throw new Error(response.statusText);
        }

        if (successMessage) {
          this.notify(successMessage);
        }

        this.setIsSubmitting && this.setIsSubmitting(false);

        return response;
      })
      .catch((error) => {
        console.error(error);

        this.notify(
          errorMessage || errorMessageHandler(error),
          "error",
          {},
          false,
          10000
        );
        this.setIsSubmitting && this.setIsSubmitting(false);
        throw new Error(errorMessage || errorMessageHandler(error));
      });
  };

  get = (url, params) => {
    this.setIsSubmitting(true);

    return this.api("get")
      .get(url, { params })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, "error");

          throw new Error(response.statusText);
        }

        this.setIsSubmitting(false);

        return response;
      })
      .catch((error) => {
        this.notify(errorMessageHandler(error), "error", {}, false, 10000);
        this.setIsSubmitting(false);

        throw error;
      });
  };

  delete = (url, body, successMessage, errorMessage) => {
    this.setIsSubmitting && this.setIsSubmitting(true);

    return this.api("delete")
      .delete(url, body || {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, "error");
          throw new Error(response.statusText);
        }

        if (successMessage) {
          this.notify(successMessage);
        }

        this.setIsSubmitting && this.setIsSubmitting(false);

        return response;
      })
      .catch((error) => {
        console.error(error);

        this.notify(
          errorMessage || errorMessageHandler(error),
          "error",
          {},
          false,
          10000
        );
        this.setIsSubmitting && this.setIsSubmitting(false);
      });
  };

  refreshApiToken = async () => {
    return this.api("post")
      .post(getApiRoute("token/refresh"), {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return null;
        }

        return response.data;
      })
      .catch(() => {
        return null;
      });
  };

  authorize = async (params) =>
    this.api("post").post(getApiRoute("token"), params);

  logout = async () => this.api("post").post(getApiRoute("token/kill"));

  emailOnboarding = async (userId) =>
    this.post(
      getApiRoute("users/:id/emailing/onboarding", { id: userId }),
      {},
      "Onboarding email sent successfully!"
    ).then((response) => response.data);

  emailOneTimeLogin = async (userId) =>
    this.post(
      getApiRoute(`users/${userId}/emailing/login-link`),
      {},
      "One time login email sent successfully!"
    ).then((response) => response.data);

  sendLoginLinkToEmail = async (email) =>
    this.post(
      getApiRoute(`me/emailing/login-link`),
      { email },
      "Login link sent to email successfully!"
    ).then((response) => response.data);

  updateUser = (id, body) => {
    return this.put(getApiRoute(`users/${id}`), body);
  };

  updateCreative = (id, body) => {
    return this.put(getApiRoute(`creatives/${id}`), body);
  };

  deleteUser = (id) => {
    return this.delete(getApiRoute(`users/${id}`));
  };

  fetchProductLogs = (productId, params) => {
    return this.get(getApiRoute(`product-logs/product/${productId}`), params);
  };

  getLoggedinUserCreative = async () => {
    let url = "me/creative";

    return await this.get(getApiRoute(url));
  };

  impersonate = async (userId) => {
    return this.post(getApiRoute(`users/${userId}/impersonate`))
  }

  importCsv = async (resource, params = {}) =>
    this.post(
      getApiRoute(`${resource}/import`),
      params,
      "File has been imported successfully",
      "prevent"
    )
      .then((response) => window.location.reload())
      .catch((error) => {
        let message = "An error occurred during import.";
        if (error.response && error.response.status === 400) {
          const jsonError = error.response.data;

          if (jsonError) {
            if (jsonError.type === "default.form.validation") {
              const violations = jsonError.violations;
              message =
                "Validation failed on line " +
                jsonError.line +
                " with messages:";
              violations.forEach((violation) => {
                message +=
                  "\n- " + violation.property + ": " + violation.message;
              });
            }
            if (jsonError.type === "default.http.400") {
              message = jsonError.title;
            }
          }
        }
        this.notify(message, "error", {}, false, 10000);
      });
  
  duplicateProduct = async (product) => {
    return this.post(getApiRoute(`products`), product)
  }
}

export const api = new Api();

export default Api;
