import OrderList from "./OrderList";
import OrderEdit from "./OrderEdit";
import BorderAllIcon from '@mui/icons-material/BorderAll';

const orders = {
  label: "Orders",
  list: OrderList,
  edit: OrderEdit,
  icon: BorderAllIcon,
};

export default orders;
