import { fetchUtils } from "react-admin";

const defaultOptions = {
  credentials: "include",
};

/**
 * @deprecated Use useApi hook instead
 */
export const httpClient = (url, options) => {
  if ("production" !== process.env.NODE_ENV) {
    console.warn(
      "!!! The httpClient is deprecated. Please use Api (useApi hook) instead."
    );
  }

  let requestOptions = options
    ? { ...defaultOptions, ...options }
    : defaultOptions;

  return fetchUtils.fetchJson(url, requestOptions);
};
