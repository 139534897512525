import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#6d6d6d",
      main: "#424242",
      dark: "#1b1b1b",
      contrastText: "#fff",
    },
    secondary: {
      light: "#5c8bc7",
      main: "#b0c1d4",
      dark: "#003568",
      contrastText: "#36619b",
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
  components: {
    RaSidebar: {
      styleOverrides: {
        root: {
          paddingTop: 24,
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "12px !important",
          marginBottom: "0 !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          background: "#ffffff !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "translate(0px, -20px) !important",
          fontSize: "14px",
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          height: "20px",
          padding: "16px 20px !important",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          alignItems: "center !important",
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
        fieldset legend span {
          display: none !important;
        }

        .RaList-actions {
          margin-bottom: 8px;
        }
        
        .RaFilter-form {
          align-items: center !important;
  
        }
      `,
    },
  },
  shape: {
    borderRadius: 0,
  },
});

export default theme;
