import PersonIcon from "@mui/icons-material/Person";
import CreativeCreate from "./CreativeCreate";
import CreativeList from "./CreativeList";
import CreativeEdit from "./CreativeEdit";

const creatives = {
  label: "Creatives",
  edit: CreativeEdit,
  list: CreativeList,
  create: CreativeCreate,
  icon: PersonIcon,
};

export default creatives;
