import React, { useState, useCallback, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  required,
  BooleanInput,
  useUpdate,
  useNotify,
  useRedirect,
  useDataProvider,
  FunctionField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
} from 'react-admin'
import { TextInput, NumberInput } from '../app/OutlinedInputs'
import { useWatch } from 'react-hook-form'
import { SectionInfo, InfoLink, SectionInputs, SectionInfoImages, SectionCard } from '../section'
import { InputAdornment } from '@mui/material'
import { ImagesInput } from '../images-input'
import { VariationsInput } from '../variations-input'
import { CategoryInput } from '../category-input'
import { Preview } from '../preview'
import { ImagesProvider } from '../../contexts/images'
import errorMessageHandler from '../../Api/errorMessageHandler'
import CustomSelect from '../atoms/CustomSelect'
import { getApiRoute } from '../../config/routes'
import { api } from '../../Api'
import { CustomRichTextInput } from '../rich-text-input'
import { VatInput } from '../vat-input'
import { useForm } from 'react-hook-form'
import withTitle from '../with-title'
import { formatInputTwoDecimals } from '../../utils/common'
import { CardComponent } from "../edit-component";

const cleanup = new Set([
  'slug',
  'tagIds',
  'createdAt',
  'deletedAt',
  'isDeleted',
  'wooUrl',
  'cart',
  'catalogViews',
  'purchases',
  'views',
  'wishlist',
  'id',
  'wpId',
])

const ProductEdit = ({ ...props }) => {
  const [update] = useUpdate()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [attributeTerms, setAttributeTerms] = useState([])
  const [creativeOptions, setCreativeOptions] = useState([])
  const [images, setImages] = useState([])
  const { register, watch, reset } = useForm()
  const [creative, setCreative] = useState(null)
  const [isOutsideNl, setIsOutsideNl] = useState(true)

  const { onChange: onChangeCreative } = register('creative')

  const fetchCreative = useCallback(async (id) => {
    await api.get(getApiRoute(`creatives/${id}`)).then((res) => {
      setCreative(res.data)
      setIsOutsideNl(
        !['nl', 'netherlands'].includes(res.data?.country?.id?.toLowerCase())
      )
    })
  }, [])

  useEffect(() => {
    const subscription = watch((values) => {
      fetchCreative(values?.creative)
    })
    return () => subscription.unsubscribe()
  }, [watch, fetchCreative])

  const SaleInput = props => {
    const watchOnSale = useWatch({ name: 'onSale' });
    
    return (
      <NumberInput
        InputProps={{ disabled: !watchOnSale }}
        validate={watchOnSale ? required() : undefined}
        min={0.01}
        step={0.01}
        options={{
          InputProps: {
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          },
        }}
        {...props}
      />
    )
  }

  const PostEditToolbar = (props) => {
    const record = useRecordContext()

    return (
      <Toolbar {...props}>
        <SaveButton />
        {record?.deletedAt ? '' : <DeleteButton />}
      </Toolbar>
    )
  }

  const save = useCallback(
    async (values, isRedirect = true) => {
      try {
        const categoryId = values?.category?.id || values.category

        if (categoryId === 15) {
          return false
        }

        const data = {
          ...values,
          variations: values.variations?.map((variation) => ({
            ...variation,
            id: undefined,
            image: variation?.image?.id || variation.image,
            attributes: variation?.attributes?.map((attribute) =>
              typeof attribute === 'object' ? attribute.id : attribute
            ),
          })),
          tags: values.tagIds,
          creative: values?.creative?.id || values.creative,
          category: categoryId,
          images: values?.images?.map((img) => img?.id || img) || [],
          vat: values.vat?.id || values.vat,
        }

        const emptyVariationsAttr = data.variations.find(
          (item) => !item.attributes.length
        )

        if (data.variations.length > 1 && emptyVariationsAttr) {
          notify('Size or Color is required for each variations', 'error')
          return false
        }

        Object.keys(data).forEach((key) => {
          if (cleanup.has(key) || key[0] === '_') {
            data[key] = undefined
          }
        })

        await update(
          'products',
          {
            id: values.id,
            data,
          },
          {
            returnPromise: true,
            onSuccess: () => {
              notify('Changes saved', 'success')
              if (isRedirect) {
                redirect('/products')
              }
            },
          }
        )
      } catch (error) {
        notify(errorMessageHandler(error), 'error', {}, false, 100000)
        reset(values)
      }
    },
    [notify, redirect, update, reset]
  )

  const fetchCreativeOptions = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList('creatives/select', {
          pagination: {},
          sort: {},
          filter: {},
        })
      ).data

      setCreativeOptions(data)
    } catch (e) {
      console.log(e)
    }
  }, [dataProvider])

  useEffect(() => {
    fetchCreativeOptions()
    api.get(getApiRoute('attribute-terms')).then((res) => {
      setAttributeTerms(res.data.data)
    })
    api.get(getApiRoute('tags')).then((res) => {
      setTags(res.data.data)
    })
    api.get(getApiRoute('categories')).then((res) => {
      setCategories(res.data)
    })

    api.getLoggedinUserCreative().then((res) => {
      setCreative(res.data)
      setIsOutsideNl(
        !['nl', 'netherlands'].includes(res.data?.country?.id?.toLowerCase())
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateSalePrice = (value, allValues) => {
    if (!value && allValues?.onSale) {
      return 'Required'
    }
  }

  const roles = JSON.parse(localStorage.getItem('roles')) || []

  const ProductTitle = () => {
    const record = useRecordContext()
    return <span>Edit product {record ? `"${record.name}"` : ''}</span>
  }

  return (
    <Edit component={CardComponent} title={<ProductTitle />} {...props}>
      <SimpleForm sx={{ padding: 0, width: '100%' }} onSubmit={save} toolbar={<PostEditToolbar />}>
        <SectionCard>
          <SectionInfo title={'Describe your product to customers'}>
            <span>Follow content best practices to drive sales</span>
            <InfoLink
              href="https://www.swanmarket.nl/support-voor-creatieven/"
              target="_blank"
            >
              Learn more
            </InfoLink>
          </SectionInfo>
          <SectionInputs>
            {roles?.includes('ROLE_ADMIN') &&
              Boolean(creativeOptions.length) && (
                <FunctionField
                  render={(record) => {
                    if (!creative) {
                      fetchCreative(record?.creative?.id)
                    }

                    return (
                      <CustomSelect
                        source="creative"
                        name="creative"
                        label="Creative"
                        variant="outlined"
                        size="small"
                        required={true}
                        record={record}
                        options={creativeOptions}
                        onChange={onChangeCreative}
                      />
                    )
                  }}
                />
              )}
            <TextInput
              autoFocus
              label="Product name"
              source="name"
              validate={required()}
            />
            <CustomRichTextInput
              source="shortDescription"
              name="shortDescription"
              label="Description"
              validate={required()}
            />
            <NumberInput
              variant="outlined"
              type="text"
              source="regularPrice"
              validate={required()}
              format={formatInputTwoDecimals}
              parse={(v) => v}
              options={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                },
              }}
            />

            <ReferenceArrayInput
              variant="outlined"
              source="tagIds"
              reference="tags"
              label="Tags"
            >
              <AutocompleteArrayInput
                optionText="name"
                size="small"
                limitChoicesToValue={5}
              />
            </ReferenceArrayInput>
            <BooleanInput source="publish" />
            <BooleanInput source="onSale" />
            <SaleInput source="salePrice" validate={validateSalePrice} />
            <TextInput source="gtin" label="GTIN" />
            <TextInput
              source="searchWords"
              label="Search words (Add comma to separate keywords)"
            />
            {!creative ? null : (
              <VatInput
                isEdit
                creativeVat={creative.vat}
                isKor={creative.kor}
                isOutsideNl={isOutsideNl}
                source="vat"
                validate={required()}
              />
            )}
          </SectionInputs>
        </SectionCard>

        <SectionCard>
          <SectionInfo title="Shipping dimensions">
            <span>
              If you want to provide other dimensions add them into the product
              description
            </span>
          </SectionInfo>

          <SectionInputs>
            <NumberInput
              type="text"
              label="Width (cm)"
              source="width"
              format={formatInputTwoDecimals}
              parse={(v) => v}
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                },
              }}
            />
            <NumberInput
              label="Length (cm)"
              source="length"
              type="text"
              format={formatInputTwoDecimals}
              parse={(v) => v}
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                },
              }}
            />
            <NumberInput
              label="Height (cm)"
              source="height"
              type="text"
              format={formatInputTwoDecimals}
              parse={(v) => v}
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                },
              }}
            />
          </SectionInputs>
        </SectionCard>
        <CategoryInput source="category" validate={required()} />
        <VariationsInput source="variations" />

        <ImagesProvider images={images} setImages={setImages}>
          <SectionCard>
            <SectionInfo title="Image gallery">
              <SectionInfoImages/>
            </SectionInfo>
            <SectionInputs>
              <ImagesInput source="images" id="images" />
            </SectionInputs>
          </SectionCard>
          <SectionCard>
            <SectionInfo title="Video link">
              <span>Only Vimeo or Youtube links are accepted</span>
            </SectionInfo>
            <SectionInputs>
              <br />
              <br />
              <TextInput multiline source="videoLink" label="Video link" />
            </SectionInputs>
          </SectionCard>
          {Boolean(attributeTerms.length &&
            tags.length &&
            categories.length &&
            (creativeOptions.length || roles?.includes("ROLE_CREATIVE"))
          ) && (
              <Preview
                {...props}
                attributeTerms={attributeTerms}
                allTags={tags}
                allCategories={categories}
                creativeOptions={creativeOptions}
              />
            )}
        </ImagesProvider>
      </SimpleForm>
    </Edit>
  )
}

export default withTitle(ProductEdit, 'Product Edit');
