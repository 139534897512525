import queryString from "query-string";
import { getApiRoute } from "../config/routes";

export const createDataProvider = ({ api }) => ({
  getList: async (resource, params = {}) => {
    const { page, perPage } = params.pagination || {};
    const { field, order } = params.sort || {};
    const query = {
      sort: field,
      sortDir: order,
      pageSize: perPage,
      page,
      ...params.filter
    };

    const url = `${getApiRoute(resource)}?${queryString.stringify(query)}`;
    
    const response = await api.get(url);
    return {
      data: response.data.data.map(item => ({ ...item, id: item.id || item.key })),
      total: response.data.total,
    };
  },

  getOne: async (resource, params = {}) => {
    const query = {
      ...params.filter
    };

    const url = `${getApiRoute(resource)}/${params.id}?${queryString.stringify(query)}`;

    const response = await api.get(url);

    return {
      data: response.data,
    };
  },

  getMany: async (resource, params = {}) => {
    const { pagination } = params;
    const page = pagination && pagination.page ? pagination.page : 0;
    const pageSize =
      pagination && pagination.perPage ? pagination.perPage : 1000;

    const query = {
      filter: JSON.stringify({ ids: params.ids }),
      pageSize,
      page,
    };
    const url = `${getApiRoute(resource)}?${queryString.stringify(query)}`;
    const response = await api.get(url);
    return response.data;
  },

  getManyReference: async (resource, params = {}) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      pageSize: perPage,
      page,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${getApiRoute(resource)}?${queryString.stringify(query)}`;

    const response = await api.get(url);
    return response.data;
  },

  update: async (resource, params = {}) => {
    const response = await api.put(
      `${getApiRoute(resource)}/${params.id}`,
      params.data
    );
    return {
      data: response.data,
    };
  },

  updateMany: async (resource, params = {}) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${getApiRoute(resource)}?${queryString.stringify(query)}`;
    const response = await api.put(url, params.data);
    return { data: response.data };
  },

  create: async (resource, params = {}) => {
    const response = await api.post(getApiRoute(resource), params.data);
    return {
      data: { ...params.data, id: response.data.id },
    };
  },

  delete: async (resource, params = {}) => {
    const response = await api.delete(`${getApiRoute(resource)}/${params.id}`);
    return { data: response.data };
  },

  deleteMany: async (resource, params = {}) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${getApiRoute(resource)}?${queryString.stringify(query)}`;
    const response = await api.delete(url, params.data);
    return { data: response.data };
  },
});
