import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useInput, useRecordContext } from "react-admin";

const CustomFormControl = styled(FormControl)`
  margin: 0 0 32px 0 !important;
`;

const CustomSelect = ({
  name,
  label,
  variant,
  size,
  options,
  error,
  disabled,
  required,
  source,
  onChange: onChangeCallback,
  ...props
}) => {
  const record = useRecordContext();
  const {
    field: { onChange },
  } = useInput({ record, source });
  const [value, setValue] = useState(record?.[name]);

  useEffect(() => {
    if (value) {
      onChange(value?.id);
    }
  }, [value, onChangeCallback, onChange]);

  return (
    <CustomFormControl
      required={required}
      fullWidth
      variant="filled"
      error={!!error}
      size={size}
    >
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        variant={variant}
        labelId={name}
        name={name}
        disabled={disabled}
        value={value?.id}
        onChange={(e) => {
          onChangeCallback(e)
          return setValue(options.find((item) => item.id === e.target.value));
        }}
        defaultValue={value ? value.id : ""}
        {...props}
      >
        {options.map((item, key) => {
          return (
            <MenuItem key={key} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </CustomFormControl>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array,
  error: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  size: PropTypes.oneOf(["small", "normal"]),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

CustomSelect.defaultProps = {
  options: [],
  disabled: false,
  required: false,
  variant: "filled",
  size: "normal",
};

export default CustomSelect;
