import React, { useEffect, useCallback, useState, useMemo } from "react";
import {
  List,
  TextField,
  Datagrid,
  NumberField,
  FunctionField,
  SelectInput,
  BooleanField,
  DateField,
  TextInput,
  Filter,
  TopToolbar,
  CreateButton,
  ExportButton,
  EditButton,
  useRedirect,
  useDataProvider,
  RefreshButton,
  BooleanInput,
  useNotify,
  SimpleList,
  NullableBooleanInput,
} from "react-admin";
import styled from "styled-components";
import ImportButton from "../csv/import";
import { Button, Tooltip } from "@mui/material";
import { getApiRoute } from "../../config/routes";
import { api } from "../../Api";
import { getUrlParams } from '../../utils/uri'
import withTitle from '../with-title'
import { useMediaQuery } from '@mui/material';
import { formatUTCDate } from "../../utils/common";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ProductDuplicateButton from "./ProductDuplicateButton";

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
`

const CustomTableLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
`


const ListFilter = (props) => {
  const dataProvider = useDataProvider()
  const [creativeOptions, setCreativeOptions] = useState([])

  const fetchCreativeOptions = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList('creatives/select', {
          pagination: {},
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
      ).data

      setCreativeOptions(data)
    } catch (e) {
      console.log(e)
    }
  }, [dataProvider])

  useEffect(() => {
    if (roles.includes('ROLE_ADMIN')) {
      fetchCreativeOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const roles = JSON.parse(localStorage.getItem('roles')) || []

  if (!roles.includes('ROLE_ADMIN')) {
    return (
      <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
      </Filter>
    )
  }

  return (
    <Filter {...props}>
      <TextInput label="Search" source="search" alwaysOn sx={{ maxWidth: '200px' }} />
      <SelectInput
        source="creative"
        choices={creativeOptions.map(({ id, name }) => ({ id, name }))}
        fullWidth
        alwaysOn
        sx={{ maxWidth: '200px'}}
      />
      <BooleanInput source="deleted" alwaysOn />
      <NullableBooleanInput source="publish" alwaysOn />
    </Filter>
  )
}
const handleDownload = () => {
  let url = 'products/export'
  const params = getUrlParams()
  const paramsParsed = params?.filter ? JSON.parse(params.filter) : {}
  const deleted = paramsParsed?.deleted
  const publish = paramsParsed?.publish


  if (deleted) {
    url = url.includes('?')
      ? `${url}&deleted=${deleted}`
      : `${url}?deleted=${deleted}`
  }

  if (publish) {
    url = url.includes('?')
      ? `${url}&publish=${publish}`
      : `${url}?publish=${publish}`
  }

  const downloadUrl = getApiRoute(url)

  window.open(downloadUrl, '_blank')
}

const handlePull = (notify = () => {}) => {
  api.post(getApiRoute("pull"));
  notify("Pulled categories, tags and attributes", "success");
};

const ListActions = (props) => {
  const roles = JSON.parse(localStorage.getItem("roles")) || [];
  const notify = useNotify()

  return (
    <TopToolbar sx={{ alignSelf: 'flex-start', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start !important', sm: 'center' } }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {roles?.includes("ROLE_ADMIN") ? (
          <RefreshButton size="medium" label="Pull" onClick={() => { handlePull(notify) }} />
        ) : (
          ""
        )}
        <CreateButton size="medium" />
        <ExportButton size="medium" onClick={handleDownload} />
        <ImportButton resource="products" text="Import" />
      </div>
      <Button
          color="primary"
          href={
            roles?.includes("ROLE_ADMIN")
              ? "admin_import.xlsx"
              : "creative_import.xlsx"
          }
          sx={{ marginBottom: { xs: '20px', sm: '0' } }}
        >
          <Tooltip
            title={
              <React.Fragment>
                1 - Create new product
                <br />
                2 - Update variation by sku
                <br />3 - Update simple product by sku
              </React.Fragment>
            }
          >
            Get import example
          </Tooltip>
        </Button>
    </TopToolbar>
  );
};

const ProductList = ({ ...props }) => {
  const redirect = useRedirect();

  const roles = useMemo(() => {
    return JSON.parse(localStorage.getItem("roles")) || []
  }, []);

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onRedirect = (e, productId) => {
    e.preventDefault();
    e.stopPropagation();

    redirect(`/product-logs/%2Fapi%2Fproduct-logs%2F${productId}/show`);
  };

  const isAdmin = useMemo(() => {
    return roles?.includes("ROLE_ADMIN")
  }, [roles])

  const mobileTableCell = (record) => {
    return (
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        {renderProductImage(record)}
        <div><b>Name:</b> {record.name}</div>
        <div><b>Regular price:</b> {record.regularPrice}</div>
        <div><b>Stock:</b> {record.kpi.stock}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>Published:</b> {record.publish ? <CheckIcon fontSize="small" sx={{ ml: '4px' }}/> : <CloseIcon/>}
        </div>
        <div><b>Catalog views:</b> {record.catalogViews || '-'}</div>
        <div><b>Views:</b> {record.views || '-'}</div>
        <div><b>Wishlist:</b> {record.wishlist}</div>
        {isAdmin &&
          <div>
            <div><b>Creative name:</b> {record.creative.name}</div>
            <div><b>Category name:</b> {getCategoryName(record)}</div>
            <div><b>Created At:</b> {formatUTCDate(record.createdAt)}</div>
            <div><b>Deleted At:</b> {formatUTCDate(record.deletedAt) || '-'}</div>
          </div>
        }
      </div>
    )
  }

  const renderProductImage = (record) => {
    let image;

    if (record?.images && Array.isArray(record.images)) {
      image = record?.images?.sort((a, b) => a.sort - b.sort)[0];
    } else {
      image = Object.values(record?.images || {}).sort(
        (a, b) => a.sort - b.sort
      )[0];
    }

    return image ? (
      <StyledImage src={image.absoluteUrl} alt="product image" />
    ) : null;
  }

  const getCategoryName = (record) => {
    return record?.category?.name.replace(/&amp;/g, "&");
  }

  return (
    <List
      {...props}
      exporter={false}
      perPage={50}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions {...props} />}
      filters={<ListFilter />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => <div><b>ID:</b> {record.id}</div>}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" label="ID" />
          <TextField source="name" label="Product name" />
          <FunctionField
            label="Image"
            render={renderProductImage}
          />
          <NumberField source="regularPrice" />
          <TextField
            label="Stock"
            source="kpi.stock"
          />
          <BooleanField source="publish" label="Published" />
          <FunctionField
            source="catalogViews"
            render={record => record.catalogViews || '-'}
          />
          <FunctionField
            source="views"
            render={record => record.views || '-'}
          />
          {/* <NumberField source="cart" /> */}
          <NumberField source="wishlist" />

          {isAdmin && (
              <TextField source="creative.name" label="Creative name" />
            )}
          {isAdmin && (
              <FunctionField
                label="Category Name"
                render={getCategoryName}
              />
            )}
          {isAdmin && (
              <DateField source="createdAt" locales="nl-Nl" />
            )}
          {isAdmin && (
            <DateField source="deletedAt" locales="nl-Nl" />
          )}

          {isAdmin && (
            <FunctionField
              label="Last Error"
              source="lastError"
              sortable={true}
              sortByOrder={"DESC"}
              render={(record) => {
                return record.recentLog ? (
                  <div>
                    {record?.recentLog?.code || ""} :{" "}
                    {record?.recentLog?.message || ""}
                    <CustomTableLink onClick={(e) => onRedirect(e, record.id)}>
                      Show all
                    </CustomTableLink>
                  </div>
                ) : null;
              }}
            />
          )}
          <FunctionField render={record => (
            <ProductDuplicateButton recordId={record.id} />
          )} />

          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default withTitle(ProductList, 'Product List');
