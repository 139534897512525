import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import {
  Create,
  SimpleForm,
  required,
  maxLength,
  email,
  BooleanInput,
  DateInput,
  ImageInput,
  ImageField,
  useNotify,
  useCreate,
  useRedirect,
  Toolbar,
  SaveButton,
  CheckboxGroupInput,
  Confirm,
  FormDataConsumer,
} from "react-admin";
import { NumberInput, TextInput } from "../app/OutlinedInputs";
import { ImagesProvider } from "../../contexts/images";
import { SectionCard, SectionInfo, SectionInfoImages, SectionInputs } from "../section";
import { getApiRoute } from "../../config/routes";
import errorMessageHandler from "../../Api/errorMessageHandler";
import useApi from "../../hooks/useApi";
import { InputAdornment } from "@mui/material";
import { ImagesInput } from "../images-input";
import { validateIBAN, transformWhatsappLink } from '../../utils/common'
import { CustomRichTextInput } from '../rich-text-input'
import { VatInput } from "../vat-input";
import withTitle from '../with-title'
import useFiles from "../../hooks/useFiles";
import { resizeImage } from "../../utils/images";
import { CountryInput } from "../country-input";
import { useForm } from "react-hook-form"
import { CardComponent } from "../edit-component";

const StyledTextInput = styled(TextInput)`
  flex: 1;
`
const StyledNumberInput = styled(NumberInput)`
  flex: 1;
`

const CreativeCreate = (props) => {
  const { api } = useApi();
  const notify = useNotify();
  const [create] = useCreate();
  const redirect = useRedirect();
  const [creative, setCreative] = useState({ image: undefined });
  const [images, setImages] = useState([]);
  const [image, setImage] = useState(null);
  const [stores, setStores] = useState([])
  const [events, setEvents] = useState([])
  const [vacationDialog, setVacationDialog] = useState(false);
  const [vacationDays, setVacationDays] = useState(null)
  const [isKor, setIsKor] = useState(false)
  const [isOutsideNl, setIsOutsideNl] = useState(false)
  const { imageMimeTypes } = useFiles()
  const { reset } = useForm();
  
  const onChangeKor = useCallback((e) => {
    setIsKor(e.target.checked)
  }, [])

  const onChangeCountry = useCallback((val) => {
    setIsOutsideNl(!['nl'].includes(val.toLowerCase()))
  }, [])

  useEffect(() => {
    api.get(getApiRoute('creative-stores')).then(({ data }) => {
      setStores(data.data)
    })
    api.get(getApiRoute('creative-events')).then(({ data }) => {
      setEvents(data.data)
    })
  }, [api])

  const save = async (values) => {
    // check vacation > 30 days
    const dateFromObj = new Date(values.onVacationFrom);
    const dateUntilObj = new Date(values.onVacationUntil);
    
    const diff = dateUntilObj - dateFromObj;
    
    const diffDays = diff / (1000 * 60 * 60 * 24);
    
    if (diffDays > 30) {
      setVacationDays(diffDays)
      setVacationDialog(true)
    } else {
      await saveFunction(values)
    }
  };

  const saveFunction = useCallback(
    async (values) => {
      try {
        const whatsappNumber = transformWhatsappLink(values.whatsappLink);
        let data = Object.assign(values, creative);

        data = {
          ...data,
          image: values.image?.id || image?.id || null,
          images: values.images?.map((image) => typeof image === 'object'
            ? image.id
            : image
          ),
          whatsappLink: whatsappNumber?.length ? `wa.me/${transformWhatsappLink(whatsappNumber[0])}` : null
        }

        await create(
          "creatives",
          {
            data,
          },
          {
            returnPromise: true,
            onSuccess: () => {
              notify("A creative is being added", "success");
              redirect("/creatives");
              window.location.reload();
            },
          }
        );
      } catch (error) {
        console.log(error);
        notify(errorMessageHandler(error), "error", {}, false, 100000);
        reset(values)
      }
    },
    [creative, image?.id, create, notify, redirect, reset]
  );

  const handleDialogClose = () => setVacationDialog(false)
  const handleConfirm = (values) => {
    saveFunction(values);
    setVacationDialog(false)
  }

  const onChangeImage = async (file) => {
    if (!file) {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
      creative.image = null;
      setCreative(creative);
    }
    let reader = new FileReader();

    reader.addEventListener("load", () => {
      let thumb = new Image();
      thumb.src = reader.result;
      thumb.addEventListener("load", async () => {
        let formData = new FormData();

        const resizedFile = await resizeImage(file)
        formData.append("file", resizedFile);
        formData.append("sort", 1);
        await api
          .post(getApiRoute("images"), formData)
          .then((resp) => {
            creative.image = resp.data.id;
            setImage(resp.data)
            setCreative(creative);
          })
          .catch((e) => {
            notify(errorMessageHandler(e), "error", {}, false, 100000);
          });
      });
    });

    if (file?.type && file?.type.indexOf('image') !== -1) {
      reader.readAsDataURL(file);
    }
  };

  const PostEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const EventOption = (record) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid gray', borderRadius: 10, padding: '5px', margin: '5px' }}>
        <b>{record.name}</b>
        <span style={{ fontSize: '14px', color: 'gray' }}>{record.takePlaceOn.formatted6}, {record.time.from} - {record.time.to}</span>
        <span style={{ fontSize: '14px', color: 'gray' }}>{record.location}</span>
      </div>
    )
  }

  return (
    <ImagesProvider images={images} setImages={setImages}>
      <Create component={CardComponent} {...props}>
        <SimpleForm sx={{ padding: 0, width: '100%' }} onSubmit={save} toolbar={<PostEditToolbar />}>
        <FormDataConsumer>
        {({ formData, ...rest }) => (
        <>
          <SectionCard>
            <SectionInfo title={'User'}></SectionInfo>
            <SectionInputs>
              <TextInput
                variant="outlined"
                source="email"
                type="email"
                validate={[required(), email()]}
                fullWidth
              />
            </SectionInputs>
          </SectionCard>
          <SectionCard>
            <SectionInfo
              title={'Creative profile'}
            ></SectionInfo>
            <SectionInputs>
              <TextInput
                variant="outlined"
                source="name"
                type="text"
                validate={[required()]}
                fullWidth
              />
              <TextInput
                variant="outlined"
                source="slug"
                type="text"
                validate={[required()]}
                fullWidth
              />
              <TextInput
                variant="outlined"
                source="skuPrefix"
                type="text"
                fullWidth
                label="SKU prefix"
              />
              <NumberInput
                label="Exact Code"
                source="eoCode"
                InputLabelProps={{ shrink: true }}
              />
              <CustomRichTextInput source="description" name="description" />
              <TextInput
                variant="outlined"
                source="metaDescription"
                type="text"
                validate={[maxLength(160)]}
                multiline
                fullWidth
                sx={{
                  [`& textarea`]: {
                    minHeight: '4em',
                  },
                }}
              />
            </SectionInputs>
          </SectionCard>

          <SectionCard>
            <SectionInfo title="Account images">
              <SectionInfoImages />
            </SectionInfo>
            <SectionInputs>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <h4 style={{ marginBottom: 0 }}>Profile image</h4>
                <ImageInput
                  onChange={onChangeImage}
                  source="image"
                  label=" "
                  sx={{ border: '3px dashed rgba(0, 0, 0, 0.12)' }}
                  accept={imageMimeTypes}
                  validateFile={(file) => {
                    console.log(file, 'file')
                  }}
                >
                  <ImageField source="absoluteUrl" title="title" />
                </ImageInput>
                {image && (
                  <div>
                    <StyledImage src={image.absoluteUrl} alt={image.id} />
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <h4 style={{ marginBottom: 0 }}>Style & impression gallery</h4>
                <ImagesInput source="images" id="images" />
              </div>
            </SectionInputs>
          </SectionCard>                    
          <SectionCard>
            <SectionInfo title={'Public information'}></SectionInfo>
            <SectionInputs>
              <BooleanInput
                label="Show on public profile page"
                name={'showSettings'}
                source="showSettings"
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput label="Website" source="url" />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showUrl"
                  name="showUrl"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput
                  label="Public email address"
                  source="publicEmail"
                />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showPublicEmail"
                  name="showPublicEmail"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput
                  label="Phone number"
                  source="phoneNumber"
                />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showPhoneNumber"
                  name="showPhoneNumber"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                }}
              >
                <StyledNumberInput
                  label="WhatsApp"
                  source="whatsappLink"
                  placeholder="31612345678"
                  helperText="Fill phone with country code"
                  format={transformWhatsappLink}
                  InputLabelProps={{ shrink: true }}
                />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showWhatsappLink"
                  name="showWhatsappLink"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput
                  label="Instagram url"
                  source="instagramLink"
                />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showInstagramLink"
                  name="showInstagramLink"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput
                  label="Facebook url"
                  source="facebookLink"
                />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showFacebookLink"
                  name="showFacebookLink"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput
                  label="Pinterest"
                  source="pinterestLink"
                />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showPinterestLink"
                  name="showPinterestLink"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput
                  label="Twitter url"
                  source="twitterLink"
                />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showTwitterLink"
                  name="showTwitterLink"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput label="Tiktok" source="tiktokLink" />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showTiktokLink"
                  name="showTiktokLink"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput label="Snapchat" source="snapchatLink" />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showSnapchatLink"
                  name="showSnapchatLink"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledTextInput label="Mastodon" source="mastodonLink" />
                <span style={{ marginLeft: '5px' }}></span>
                <BooleanInput
                  label="Show on site"
                  source="showMastodonLink"
                  name="showMastodonLink"
                />
              </div>
            </SectionInputs>
          </SectionCard>

          <SectionCard>
            <SectionInfo title={'Business information'}></SectionInfo>
            <SectionInputs>
              <TextInput
                label="Address"
                source="streetAddress"
              />
              <TextInput label="Postal code" source="postalCode" />
              <TextInput label="City" source="city" />
              <CountryInput isCreate label="Country" source="country" onChange={onChangeCountry} />
              <TextInput
                label="Bank account IBAN"
                source="iban"
                validate={validateIBAN}
              />
              <TextInput
                label="Bank account holder (tenaamstelling)"
                source="bankAccountHolder"
              />
              <TextInput
                label="COC Number (KVK nummer)"
                source="kvkNumber"
              />
              <TextInput label="VAT Number (BTW nummer)" source="btwNumber" />
              <VatInput
                isKor={isKor}
                isOutsideNl={isOutsideNl}
                source="vat"
                label="VAT setting"
                validate={required()}
              />

              <BooleanInput
                source="kor"
                label="KOR (Small Business Agreement)"
                onChange={onChangeKor}
              />
              <TextInput label="WebwinkelKeur ID" source="webwinkelId" />
            </SectionInputs>
          </SectionCard>

          <SectionCard>
            <SectionInfo title={'Account settings'}></SectionInfo>
            <SectionInputs>
              <BooleanInput source="active" defaultValue={true} />
              <BooleanInput name={'member'} source="member" />
              <NumberInput
                label="Commission percentage"
                source="commissionPercentage"
                options={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  },
                }}
              />

              <BooleanInput
                source="publishProductsDirectly"
                label="Publish products automatically"
              />

              <BooleanInput
                label="Receive new order emails"
                source="sendNewOrderEmail"
                name="sendNewOrderEmail"
                defaultValue={true}
                defaultChecked={true}
              />

              <TextInput label="Hubspot ID" source="hsId" />
              <Confirm
                isOpen={vacationDialog}
                title={`Are you sure you are on holiday for ${vacationDays} days?`}
                onConfirm={() => {
                  handleConfirm(formData)
                }}
                onClose={handleDialogClose}
              />
              <DateInput variant="outlined" source="onVacationFrom" />
              <DateInput variant="outlined" source="onVacationUntil" />
            </SectionInputs>
          </SectionCard>

          {Boolean(stores.length) && (
            <SectionCard>
              <SectionInfo title={'Stores'}>
                My products are available in the following Swan stores:
              </SectionInfo>
              <SectionInputs>
                <CheckboxGroupInput
                  source="stores"
                  optionValue="id"
                  optionText="name"
                  choices={stores}
                  row={false}
                />
              </SectionInputs>
            </SectionCard>
          )}
          {Boolean(events.length) && (
            <SectionCard>
              <SectionInfo title={'Events'}>
                I have a stand at the following Swan markets
              </SectionInfo>
              <SectionInputs>
                <CheckboxGroupInput
                  source="events"
                  optionValue="id"
                  optionText={EventOption}
                  choices={events}
                  row={false}
                />
              </SectionInputs>
            </SectionCard>
          )}
        </>
        )}
        </FormDataConsumer>
        </SimpleForm>
      </Create>
    </ImagesProvider>
  )
};

const StyledImage = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;
`;

export default withTitle(CreativeCreate, 'Creative Create');
