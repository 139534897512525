import PersonIcon from "@mui/icons-material/Person";
import CustomPage from "./CustomPage";

const customPages = {
  label: "Custom page",
  edit: CustomPage,
  icon: PersonIcon,
};

export default customPages;
