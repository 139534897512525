import React, { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import useApi from "../../hooks/useApi";
import { getNumberFromStringId } from "../../utils/uri";
import { LOGS_TABLE_HEADER } from "../../utils/common";
import withTitle from '../with-title'

const ProductLog = () => {
  const { api, isSubmitting } = useApi();
  const stringId = decodeURIComponent(window.location.href.split("/").at(-2));
  const productId = getNumberFromStringId(stringId);
  const [logsData, setLogsData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  const fetchProductLogs = useCallback(async () => {
    try {
      const logs = (
        await api.fetchProductLogs(productId, {
          page: page + 1,
          pageSize: limit,
        })
      ).data;

      setLogsData(logs);
    } catch (e) {}
  }, [page, limit, api, productId]);

  useEffect(() => {
    let mount = true;

    if (mount) {
      fetchProductLogs();
    }

    return () => (mount = false);
  }, [page, limit, fetchProductLogs]);
  
  return (
    <>
      <h2>Error log for #{productId}</h2>

      <DataGrid
        className="custom-table"
        headerHeight={37}
        rows={logsData?.data || []}
        columns={LOGS_TABLE_HEADER}
        page={page}
        pageSize={limit}
        autoHeight
        disableColumnMenu
        loading={isSubmitting}
        paginationMode="server"
        rowCount={logsData?.count}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(limit) => setLimit(limit)}
        sx={{
          '.MuiDataGrid-cell': {
            whiteSpace: 'normal',
            lineHeight: 'normal !important',
            alignItems: 'flex-start',
            maxWidth: '100%',
            maxHeight: 'none !important',
          },
          '.MuiDataGrid-row': {
            maxHeight: 'none !important',
          }
        }}
      />
    </>
  );
};

export default withTitle(ProductLog, 'Product Log');
