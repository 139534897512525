import React, {useCallback, useState} from "react";
import {
  Create,
  SimpleForm,
  required,
  email, useNotify, useRedirect, useCreate,
} from "react-admin";
import { TextInput } from "../app/OutlinedInputs";
import errorMessageHandler from "../../Api/errorMessageHandler";
import withTitle from '../with-title'

const UserCreate = (props) => {
  const notify = useNotify();
  const [user] = useState({});
  const [create] = useCreate();
  const redirect = useRedirect();

  const save = useCallback(
      async (values) => {
        try {
          let data = Object.assign(values, user);

          await create(
              "users",
              {
                data,
              },
              {
                returnPromise: true,
                onSuccess: () => {
                  notify("An admin is being added", "success");
                  redirect("/users");
                },
              }
          );
        } catch (error) {
          console.log(error);
          notify(errorMessageHandler(error), "error", {}, false, 100000);
        }
      },
      [notify, redirect, user, create]
  );

  return (
    <Create {...props}>
      <SimpleForm redirect="list" onSubmit={save}>
        <TextInput
          source="email"
          type="email"
          validate={[required(), email()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default withTitle(UserCreate, 'User Create');