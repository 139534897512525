import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";

import useApi from "../../../hooks/useApi";
import { useRecordContext } from "react-admin";

export const EmailOnboardingButton = React.memo((props) => {
  const { api, isSubmitting } = useApi();
  const record = useRecordContext();

  const onResetClick = useCallback(
    (e) => {
      api.emailOnboarding(record.id);
      e.stopPropagation();
      e.preventDefault();
    },
    [record, api]
  );

  return (
    <Button onClickCapture={onResetClick} disabled={isSubmitting} {...props}>
      <EmailIcon /> Onboarding
    </Button>
  );
});
