import EventIcon from '@mui/icons-material/Event';
import EventCreate from "./EventCreate";
import EventEdit from "./EventEdit";
import EventList from "./EventList";

const events = {
  list: EventList,
  create: EventCreate,
  edit: EventEdit,
  icon: EventIcon,
};

export default events;
