import PersonIcon from "@mui/icons-material/Person";
import CmsBlockList from "./CmsBlockList";
import CmsBlockEdit from "./CmsBlockEdit";

const cmsBlocks = {
  label: "Cms blocks",
  list: CmsBlockList,
  edit: CmsBlockEdit,
  icon: PersonIcon,
};

export default cmsBlocks;
