import {
  TextInput as RaTextInput,
  NumberInput as RaNumberInput,
  SelectInput as RaSelectInput,
  DateInput as RaDateInput,
  DateTimeInput as RaDateTimeInput,
  NullableBooleanInput as RaNullableBooleanInput,
  AutocompleteInput as RaAutocompleteInput,
} from "react-admin";

const standardize = (Component) => (props) =>
  (
    <Component
      variant="outlined"
      InputLabelProps={{ shrink: false }}
      {...props}
    />
  );

export const TextInput = standardize(RaTextInput);
export const NumberInput = standardize(RaNumberInput);
export const SelectInput = standardize(RaSelectInput);
export const DateInput = standardize(RaDateInput);
export const DateTimeInput = standardize(RaDateTimeInput);
export const NullableBooleanInput = standardize(RaNullableBooleanInput);
export const AutocompleteInput = standardize(RaAutocompleteInput);
