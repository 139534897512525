import * as React from "react";
import {
  List,
  Datagrid,
  DeleteButton,
  TextField,
  UrlField,
  ImageField,
  SimpleList
} from "react-admin";
import withTitle from '../with-title'
import defaultImage from '../../assets/images/file.png'
import { useMediaQuery } from '@mui/material';

const FileList = ({ ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onErrorImage = (e) => {
    e.target.src = defaultImage
  }

  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  const mobileTableCell = (record) => {
    return (
      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <img src={record.url} onError={onErrorImage} alt={record.name} width='30%'/>
          <DeleteButton />
        </div>
        <div><b>Name:</b> {record.name}</div>
        <div><b>URL:</b> <a href={record.url} target="_blank" rel="noreferrer" onClick={handleLinkClick}>{record.url}</a></div>
      </div>
    )
  }

  return (
    <List
      {...props}
      perPage={50}
      exporter={false}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => ''}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label={"Name"} />
          <ImageField source="url" label={"Thumb"} onError={onErrorImage} sx={{ maxWidth: '80px', '& img': { width: '100% !important' }}} />
          <UrlField source="url" target='_blank' label={"URL"} />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export default withTitle(FileList, 'File List');
