import React, { useEffect, useState, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  NumberField,
  FunctionField,
  Filter,
  TextInput,
  SelectInput,
  useDataProvider,
  TopToolbar,
  ExportButton,
  Button,
} from "react-admin";
import withTitle from '../with-title'
import { useMediaQuery } from '@mui/material';
import PopupList from "../popup-list";
import { Link } from 'react-router-dom';
import { getApiRoute } from "../../config/routes";
import { Dialog, DialogTitle, DialogContent, TextField as TextFieldMui, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import useApi from "../../hooks/useApi";
import CloseIcon from '@mui/icons-material/Close';

const ListFilter = (props) => {
    const dataProvider = useDataProvider()
    const [creativeOptions, setCreativeOptions] = useState([])
    const [statuses, setStatuses] = useState([])


  const fetchCreativeOptions = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList('creatives/select', {
          pagination: {},
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
      ).data

      setCreativeOptions(data)
    } catch (e) {
      console.log(e)
    }
  }, [dataProvider])
  const fetchStatuses = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList('order-statuses', {
          pagination: {},
          filter: {},
        })
      ).data
      setStatuses(data)
    } catch (e) {
      console.log(e)
    }
  }, [dataProvider])

    useEffect(() => {
      fetchCreativeOptions()
      fetchStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const roles = JSON.parse(localStorage.getItem('roles')) || []

    return (
        <Filter {...props}>
            <TextInput label="Search" source="search" name="search" alwaysOn sx={{ maxWidth: '200px' }} />  
            <SelectInput
                source="status"
                choices={statuses.map(({ id, name }) => ({ id, name }))}
                fullWidth
                alwaysOn
                sx={{ maxWidth: '200px'}}
            />
            {roles.includes('ROLE_ADMIN') &&
              <SelectInput
                source="creative"
                choices={creativeOptions.map(({ id, name }) => ({ id, name }))}
                fullWidth
                alwaysOn
                sx={{ maxWidth: '200px'}}
              />  
            } 
        </Filter>
    )
}
  

const OrderList = ({ ...props }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [dialogMonth, setDialogMonth] = useState(false);
  const [dialogQuarter, setDialogQuarter] = useState(false);
  const [dialogEO, setDialogEO] = useState(false);
  const [entryNumber, setEntryNumber] = useState(null);
  const [exportMonth, setExportMonth] = useState(dayjs())
  const [exportYear, setExportYear] = useState(dayjs())
  const [exportQuarter, setExportQuarter] = useState(Math.ceil(dayjs().month() / 4))
  const { api } = useApi();

  const roles = JSON.parse(localStorage.getItem('roles')) || []

  const handleOpenExportMonthDialog = () => {
    prefillDialogs()
    setDialogMonth(true);
  };

  const handleOpenExportQuarterDialog = () => {
    prefillDialogs()
    setDialogQuarter(true);
  };

  const handleOpenExportEODialog = () => {
    prefillDialogs()
    setDialogEO(true);
  };

  const handleCloseExportMonthDialog = () => {
    setDialogMonth(false);
  };

  const handleCloseExportQuarterDialog = () => {
    setDialogQuarter(false);
  };

  const handleCloseExportEODialog = () => {
    setDialogEO(false);
  };

  const prefillEntryNumber = useCallback(async () => {
    await api.get(getApiRoute('settings/finance')).then(({ data }) => {
      if (data.export.orders.entryNumber) {
        setEntryNumber(data.export.orders.entryNumber)
      }
    })
  }, [api])

  const prefillDialogs = useCallback(() => {
    setExportMonth(dayjs())
    setExportYear(dayjs())
    prefillEntryNumber()
    setExportQuarter(Math.ceil(dayjs().month() / 4))
  }, [prefillEntryNumber])

  const handleDownload = useCallback(async () => {
    const year = exportYear.year()
    const month = exportMonth.month() + 1
    let url = dialogMonth ? 'orders/export' : 'orders/export/revenue'
  
    if (dialogMonth) {
      url = url.includes('?')
      ? `${url}&year=${year}&month=${month}`
      : `${url}?year=${year}&month=${month}`
    } else {
      url = url.includes('?')
      ? `${url}&year=${year}&quarter=${exportQuarter}`
      : `${url}?year=${year}&quarter=${exportQuarter}`
    }

    if (!dialogMonth && entryNumber) {
      url = url.includes('?') ? `${url}&entryNumber=${entryNumber}` : `${url}?entryNumber=${entryNumber}`
    }
  
    const downloadUrl = getApiRoute(url)

    window.open(downloadUrl, '_blank')

    // const jsonData = await api.get(getApiRoute(url))
    // csvExport(`orders_${year}_${dialogMonth ? month : exportQuarter}`, jsonData.data.data)
    setDialogQuarter(false)
    setDialogMonth(false)
    // window.open(downloadUrl, '_blank')
  }, [dialogMonth, exportMonth, exportQuarter, exportYear, entryNumber])

  const handleDownloadEO = useCallback(() => {
    const year = exportYear.year()
    const month = exportMonth.month() + 1
    let url = 'orders/export/exact-online'
    url = url.includes('?')
      ? `${url}&year=${year}&month=${month}`
      : `${url}?year=${year}&month=${month}`
  
    const downloadUrl = getApiRoute(url)
    setDialogEO(false)
  
    window.open(downloadUrl, '_blank')
  }, [exportMonth, exportYear])

  const ListActions = (props) => {
    return (
      <TopToolbar sx={{ alignSelf: 'flex-start', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start !important', sm: 'center' } }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ExportButton size="medium" onClick={handleOpenExportMonthDialog} label="Export by month"/>
            {roles.includes('ROLE_ADMIN') &&
            <ExportButton size="medium" onClick={handleOpenExportQuarterDialog} label="Export by quarter"/>
            }
            {roles.includes('ROLE_ADMIN') &&
            <ExportButton size="medium" onClick={handleOpenExportEODialog} label="Export for EO"/>
            }

        </div>
      </TopToolbar>
    );
  };

  const onClickLink = useCallback((event) => {
    event.stopPropagation();
  }, [])

  const mobileTableCell = (record) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div><b>Number:</b> {record.number}</div>
        <div><b>Status:</b> {record.status.name}</div>
        <div><b>Email:</b> {record.customer.email}</div>
        <div><b>Payment method:</b> {record.payment.method}</div>
        <div><b>Created At:</b> {record.createdAt.formatted3}</div>
        <div><b>Completed At:</b> {record.completedAt.formatted3}</div>
        <div style={{ margin: '10px 0 10px 0' }}><b>Lines:</b></div>

        <div style={{ background: 'rgba(0,0,0,0.05)'}}>
            {record.lines.map((line) => 
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0 0px 10px 20px' }}>
                    {line.product ?
                    <Link to={`/products/${line.product.id}`}>
                        {line.product.id}
                    </Link> : 'N/A' }
                    <div><b>Name:</b> {line.name}</div>
                    <div><b>Type:</b> {line.type}</div>
                    <div><b>Quantity:</b> {line.price.qty}</div>
                    <div><b>Unit Price:</b> {line.price.unit}</div>
                    <div><b>Total:</b> {line.price.total}</div>
                </div>
            )}
        </div>
      </div>
    )
  }

  return (
    <>
    <List
      {...props}
      perPage={50}
      exporter={false}
      hasCreate={false}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions {...props}/>}
      filters={<ListFilter />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => ''}
          secondaryText={mobileTableCell}
        />
      ) : (
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField source="number" label={"Number"} />
          <TextField source="status.name" label={"Status"} />
          <FunctionField label="Customer" render={record => 
            record.customer ? 
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <b>{record.customer.email}</b>
              <span style={{ color: 'gray', fontSize: 14}}>{record.customer.firstName} {record.customer.lastName}</span>
            </div>
            : 'N/A'}
          />
          <TextField source="payment.method" label={"Payment method"} />
          <PopupList source="lines" triggerText="Show+" { ...props }>
            <FunctionField label="Product" render={record => 
                record.product ? 
                <Link to={`/products/${record.product.id}`} target="_blank" onClick={onClickLink}>
                    {record.product.id}
                </Link>
                : 'N/A'}
            />
            <TextField source="name" label="Name" />
            <TextField source="type" label="Type" />
            <NumberField source="price.qty" label="Quantity" />
            <NumberField source="price.unit" label="Unit Price" />
            <NumberField source="price.total" label="Total" />
          </PopupList>
          <TextField source="createdAt.formatted3" label={"Created At"} sortBy="createdAt" />
          <TextField source="completedAt.formatted3" label={"Completed At"} sortable={false} />
        </Datagrid>
      )}
    </List>
    <Dialog onClose={handleCloseExportEODialog} open={dialogEO}>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Export orders for EO
          <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseExportEODialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', gap: 10, padding: '10px 0'}}>
          <DatePicker label="Year" views={['year']} value={exportYear} onChange={setExportYear} />
          <DatePicker label="Month" views={['month']} value={exportMonth} onChange={setExportMonth} />
        </div>
        <Button variant="contained" color="primary" fullWidth onClick={handleDownloadEO} label="Export" />
      </DialogContent>
    </Dialog>
    <Dialog onClose={handleCloseExportMonthDialog} open={dialogMonth}>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Export orders by month
          <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseExportMonthDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', gap: 10, padding: '10px 0'}}>
          <DatePicker label="Year" views={['year']} value={exportYear} onChange={setExportYear} />
          <DatePicker label="Month" views={['month']} value={exportMonth} onChange={setExportMonth} />
        </div>
        <Button variant="contained" color="primary" fullWidth onClick={handleDownload} label="Export" />
      </DialogContent>
    </Dialog>
    <Dialog onClose={handleCloseExportQuarterDialog} open={dialogQuarter}>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Export orders by quarter
          <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseExportQuarterDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', gap: 10, padding: '10px 0'}}>
          <DatePicker label="Year" views={['year']} value={exportYear} onChange={setExportYear} />
          <TextFieldMui type="number" label="Quarter (1-4)" value={exportQuarter} min={1} max={4} onChange={(e) => { setExportQuarter(e.target.value) }} />
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <TextFieldMui type="number" label="Entry number" fullWidth value={entryNumber} onChange={(e) => { setEntryNumber(e.target.value) }} />
        </div>

        <Button variant="contained" color="primary" fullWidth onClick={handleDownload} label="Export" />
      </DialogContent>
    </Dialog>
    </>  );
};

export default withTitle(OrderList, 'Order List');
