import React, { useMemo, useCallback } from "react";
import Button from "@mui/material/Button";
import { useRecordContext } from "react-admin";
import { sources } from "../../../config/routes";

export const ImpersonateButton = React.memo((props) => {
  const record = useRecordContext()
    const onClickImpersonate = useCallback(
    async (e) => {
      e.stopPropagation();
    },
    []
  );

  const link = useMemo(() => {
    return `${sources[window.location.host]}/api/users/${record?.user?.id}/impersonate`
  }, [record?.user?.id])

  return (
    <Button href={link} target="_blank" onClickCapture={onClickImpersonate} disabled={!record?.user} {...props}>
      Impersonate
    </Button>
  );
});
