import React, { useCallback } from "react";
import {
  Create,
  SimpleForm,
  ImageField,
  useNotify,
  useCreate,
  useRedirect,
  BooleanInput,
  FormDataConsumer,
  FileInput
} from "react-admin";
import { TextInput } from "../app/OutlinedInputs";
import { Section, SectionInfo, SectionInputs } from "../section";
import errorMessageHandler from "../../Api/errorMessageHandler";
import withTitle from '../with-title'
import defaultImage from '../../assets/images/file.png'
import useFiles from "../../hooks/useFiles";
import { getApiRoute } from "../../config/routes";
import useApi from "../../hooks/useApi";

const CmsPageCreate = (props) => {
  const notify = useNotify();
  const [create] = useCreate();
  const redirect = useRedirect();
  const { filesFormat, imageMimeTypes } = useFiles()
  const { api } = useApi();


  const onChangeFile = useCallback((file) => {
    if(!file || !file.length) {
      notify(`Bestandsformaat niet ondersteund. Bruikbare bestandsformaten zijn: JPG en PNG.`, 'error')
    }
  }, [notify])

  const save = useCallback(
    async (values) => {
      try {
        let data = values;

        let iconKey = null
        if (data.icon) {
          const files = [values.icon.rawFile]
          const filesArr = await filesFormat(files)
          const res = await api.post(getApiRoute("files"), filesArr).catch((e) => {
            notify(errorMessageHandler(e), "error", {}, false, 100000);
          });
          iconKey = res.data[0]?.data?.key
        }

        data = {
          ...data,
          icon: iconKey || null,
        }

        await create(
          "cms-pages",
          {
            data,
          },
          {
            returnPromise: true,
            onSuccess: () => {
              notify("A page is being added", "success");
              redirect("/cms-pages");
            },
          }
        );
      } catch (error) {
        console.log(error);
        notify(errorMessageHandler(error), "error", {}, false, 100000);
      }
    },
    [create, filesFormat, api, notify, redirect]
  );

  const onErrorImage = (e) => {
    e.target.src = defaultImage
  }

  return (
    <Create {...props}>
      <SimpleForm onSubmit={save}>
        <Section>
          <SectionInfo title={"Page details"}></SectionInfo>
          <SectionInputs>
            <TextInput
              variant="outlined"
              source="title"
              type="text"
              label="Page title"
              fullWidth
            />
            <TextInput
              variant="outlined"
              source="menu"
              label="Menu name"
              type="text"
              fullWidth
            />
            <TextInput
              variant="outlined"
              source="url"
              type="text"
              fullWidth
            />
            <BooleanInput name={"membersOnly"} label='Show for members' source="membersOnly" />
            <BooleanInput name={"nonMembersOnly"} label='Show for non-members' source="nonMembersOnly" />
          </SectionInputs>
        </Section>
        <Section>
          <SectionInfo title={"HTML Body"}></SectionInfo>
          <SectionInputs>
            <TextInput
              multiline
              source="body"
              label="Body"
              sx={{
                [`& textarea`]: {
                  minHeight: '20em',
                },
              }}
            />
          </SectionInputs>
        </Section>
        <Section>
          <SectionInfo title={"Icon"}></SectionInfo>
          <SectionInputs>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                <FileInput source="icon" accept={imageMimeTypes} onChange={onChangeFile}>
                  <ImageField source={formData?.icon?.url ? 'url' : 'src'} onError={onErrorImage} />
                </FileInput>
              )}}
            </FormDataConsumer>
          </SectionInputs>
        </Section>
      </SimpleForm>
    </Create>
  );
};

export default withTitle(CmsPageCreate, 'CMS Page Create');
