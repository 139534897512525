import PersonIcon from "@mui/icons-material/Person";
import CmsPageCreate from "./CmsPageCreate";
import CmsPageList from "./CmsPageList";
import CmsPageEdit from "./CmsPageEdit";

const cmsPages = {
  label: "Creatives",
  edit: CmsPageEdit,
  list: CmsPageList,
  create: CmsPageCreate,
  icon: PersonIcon,
};

export default cmsPages;
