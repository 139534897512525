import React, { useState, useCallback, useEffect } from "react";
import {
  Create,
  SimpleForm,
  BooleanInput,
  required,
  useCreate,
  useNotify,
  useRedirect,
  useDataProvider,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { TextInput, NumberInput } from "../app/OutlinedInputs";
import { InputAdornment } from "@mui/material";
import { useWatch } from "react-hook-form";
import { VariationsInput } from "../variations-input";
import { CategoryInput } from "../category-input";
import { Preview } from "../preview";
import { ImagesProvider } from "../../contexts/images";
import { SectionInfo, InfoLink, SectionInputs, SectionInfoImages, SectionCard } from "../section";
import errorMessageHandler from "../../Api/errorMessageHandler";
import CustomSelect from "../atoms/CustomSelect";
import { ImagesInput } from "../images-input";
import { CustomRichTextInput } from '../rich-text-input'
import { getApiRoute } from "../../config/routes";
import { api } from "../../Api";
import { VatInput } from "../vat-input";
import { useForm } from "react-hook-form";
import withTitle from '../with-title'
import { formatInputTwoDecimals } from '../../utils/common'
import { CardComponent } from "../edit-component";

const ProductCreate = ({ ...props }) => {
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const [creativeOptions, setCreativeOptions] = useState([])
  const [images, setImages] = useState([])
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [attributeTerms, setAttributeTerms] = useState([])
  const { register, watch, reset } = useForm()
  const [creative, setCreative] = useState(null)
  const [isOutsideNl, setIsOutsideNl] = useState(true)

  const { onChange: onChangeCreative } = register('creative')

  const fetchCreative = useCallback(async (id) => {
    await api.get(getApiRoute(`creatives/${id}`)).then((res) => {
      setCreative(res.data)
      setIsOutsideNl(
        !['nl', 'netherlands'].includes(res?.data?.country?.id?.toLowerCase())
      )
    })
  }, [])

  useEffect(() => {
    const subscription = watch((values) => {
      fetchCreative(values?.creative)
    })
    return () => subscription.unsubscribe()
  }, [watch, fetchCreative])

  useEffect(() => {
    fetchCreativeOptions()
    api.get(getApiRoute('attribute-terms')).then((res) => {
      setAttributeTerms(res.data.data)
    })
    api.get(getApiRoute('tags')).then((res) => {
      setTags(res.data.data)
    })
    api.get(getApiRoute('categories')).then((res) => {
      setCategories(res.data)
    })

    api.getLoggedinUserCreative().then((res) => {
      setCreative(res.data)
      setIsOutsideNl(
        !['nl', 'netherlands'].includes(res.data?.country?.id?.toLowerCase())
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SaleInput = (props) => {
    const watchOnSale = useWatch({ name: 'onSale' })

    return (
      <NumberInput
        InputProps={{ disabled: !watchOnSale }}
        validate={watchOnSale ? required() : undefined}
        min={0.01}
        step={0.01}
        options={{
          InputProps: {
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          },
        }}
        {...props}
      />
    )
  }

  const save = useCallback(
    async (values) => {
      try {
        const data = {
          ...values,
          images: values?.images?.map((img) => img?.id || img) || [],
          variations: values.variations?.map((variation) => ({
            ...variation,
            id: undefined,
            image: variation?.image?.id || variation.image,
            attributes: variation?.attributes?.map((attribute) =>
              typeof attribute === 'object' ? attribute.id : attribute
            ),
          })),
          tags: values.tagIds,
        }

        const emptyVariationsAttr = data.variations.find(
          (item) => !item.attributes.length
        )

        if (data.variations.length > 1 && emptyVariationsAttr) {
          notify('Size or Color is required for each variations', 'error')
          return false
        }

        Object.keys(data)
          .filter((key) => key[0] === '_')
          .forEach((key) => (data[key] = undefined)) // clean up internal variation fields
        let url = 'products'

        await create(
          url,
          { data },
          {
            returnPromise: true,
            onSuccess: () => {
              notify('Product will be synced until tomorrow', 'success')
              redirect('/products')
              window.location.reload();
            },
          }
        )
      } catch (error) {
        notify(errorMessageHandler(error), 'error', {}, false, 100000)
        reset(values)
      }
    },
    [notify, redirect, create, reset]
  )

  const fetchCreativeOptions = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList('creatives/select', {
          pagination: {},
          sort: {},
          filter: {},
        })
      ).data

      setCreativeOptions(data)
    } catch (e) {
      console.log(e)
    }
  }, [dataProvider])

  useEffect(() => {
    fetchCreativeOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const roles = JSON.parse(localStorage.getItem('roles')) || []

  return (
    <ImagesProvider images={images} setImages={setImages}>
      <Create component={CardComponent} title="Add a new product" {...props}>
        <SimpleForm sx={{ padding: 0, width: '100%' }} onSubmit={save}>
          <SectionCard>
            <SectionInfo title="Describe your product to customers">
              <span>Follow content best practices to drive sales</span>
              <InfoLink
                href="https://www.swanmarket.nl/support-voor-creatieven/"
                target="_blank"
              >
                Learn more
              </InfoLink>
            </SectionInfo>
            <SectionInputs>
              {roles?.includes('ROLE_ADMIN') && creativeOptions.length > 0 && (
                <CustomSelect
                  source="creative"
                  name="creative"
                  label="Creative"
                  variant="outlined"
                  size="small"
                  required
                  options={creativeOptions}
                  onChange={onChangeCreative}
                />
              )}
              <TextInput
                autoFocus
                label="Product name"
                source="name"
                validate={required()}
              />
              <CustomRichTextInput
                source="shortDescription"
                name="shortDescription"
                label="Description"
                validate={required()}
              />
              <NumberInput
                variant="outlined"
                source="regularPrice"
                validate={required()}
                type="text"
                format={formatInputTwoDecimals}
                parse={(v) => v}
                options={{
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  },
                }}
              />

              <ReferenceArrayInput
                variant="outlined"
                source="tagIds"
                reference="tags"
                label="Tags"
              >
                <AutocompleteArrayInput
                  optionText="name"
                  size="small"
                  limitChoicesToValue={5}
                />
              </ReferenceArrayInput>
              <BooleanInput source="publish" defaultValue={true} />
              <BooleanInput source="onSale" />
              <SaleInput source="salePrice" />
              <TextInput
                source="searchWords"
                label="Search words (Add comma to separate keywords)"
              />
              {!creative ? null : (
                <VatInput
                  creativeVat={creative.vat}
                  isKor={creative.kor}
                  isOutsideNl={isOutsideNl}
                  source="vat"
                  validate={required()}
                />
              )}
            </SectionInputs>
          </SectionCard>

          <SectionCard>
            <SectionInfo title="Shipping dimensions">
              <span>
                If you want to provide other dimensions add them into the
                product description
              </span>
            </SectionInfo>

            <SectionInputs>
              <NumberInput
                source="width"
                label="Width (cm)"
                type="text"
                format={formatInputTwoDecimals}
                parse={(v) => v}
                options={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  },
                }}
              />
              <NumberInput
                source="length"
                label="Length (cm)"
                type="text"
                format={formatInputTwoDecimals}
                parse={(v) => v}
                options={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  },
                }}
              />
              <NumberInput
                source="height"
                label="Height (cm)"
                type="text"
                format={formatInputTwoDecimals}
                parse={(v) => v}
                options={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  },
                }}
              />
            </SectionInputs>
          </SectionCard>
          <CategoryInput source="category" validate={required()} />
          <VariationsInput source="variations" />

          <SectionCard>
            <SectionInfo title="Image gallery">
              <SectionInfoImages/>
            </SectionInfo>
            <SectionInputs>
              <ImagesInput source="images" id="images" />
            </SectionInputs>
          </SectionCard>
          <SectionCard>
            <SectionInfo title="Video link">
              <span>Only Vimeo or Youtube links are accepted</span>
            </SectionInfo>
            <SectionInputs>
              <br />
              <br />
              <TextInput multiline source="videoLink" label="Video link" />
            </SectionInputs>
          </SectionCard>
          {Boolean(attributeTerms.length &&
            tags.length &&
            categories.length &&
            creativeOptions.length) && (
              <Preview
                {...props}
                attributeTerms={attributeTerms}
                allTags={tags}
                allCategories={categories}
                creativeOptions={creativeOptions}
              />
            )}
        </SimpleForm>
      </Create>
    </ImagesProvider>
  )
}

export default withTitle(ProductCreate, 'Product Create');
