import { useEffect, memo, useCallback } from 'react'
import { getUrlParams } from "../../utils/uri";
import useApi from '../../hooks/useApi';
import { getApiRoute } from '../../config/routes'
import { useRedirect } from 'react-admin'
import loginBck from '../../assets/images/login-swan.jpg'

export const OneTimeLogin = memo(() => {
    const { token } = getUrlParams();
    const redirect = useRedirect();
    const { api } = useApi()

    const onLogin = useCallback(async (token) => {
        try {
            await api.post(getApiRoute(`users/login-link/${token}`))

            await api.get(getApiRoute("me")).then((meData) => {
              localStorage.setItem("roles", JSON.stringify(meData.data.roles));
            });
          
            localStorage.setItem("authorized", "yes");
            redirect("/#")
        } catch (error) {
            console.log(error, 'error')
            redirect("login")
        }

    }, [api, redirect])
    useEffect(() => {
        if (token) {
            document.body.style.backgroundImage = `url("${loginBck}")`
            document.body.style.backgroundPosition = 'center'
            document.body.style.backgroundSize = 'cover'
            document.body.style.backgroundRepeat = 'no-repeat'
            document.body.style.height = '100vh'
            onLogin(token)
        } else {
            redirect("login")
        }
    }, [onLogin, redirect, token])

    return (
        <span></span>
    )
})