import React, { useCallback, useState } from 'react'
import {
  Login as BaseLogin,
  useNotify,
  Notification,
  useRedirect,
} from 'react-admin'
import { TextField, Button, CssBaseline } from '@mui/material'
import styled from 'styled-components'
import { getApiRoute } from '../../config/routes'
import errorMessageHandler from '../../Api/errorMessageHandler'
import useApi from '../../hooks/useApi'
import withTitle from '../with-title'
import loginBck from '../../assets/images/login-swan.jpg'

const InputsWrapper = styled.div`
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SubmitWrapper = styled.div`
  padding: 0 1em 1em 1em;
`

export const ResetPassword = withTitle(React.memo(() => {
  const [email, setEmail] = useState('')
  const notify = useNotify()
  const redirect = useRedirect()
  const { api } = useApi()

  const submit = useCallback(
    async (e) => {
      e.preventDefault()

      try {
        await api.post(getApiRoute('users/reset-password'), {
          email,
        })

        notify(
          'An email has been sent to your address with further instructions',
          'success',
          {},
          false,
          5000
        )

        setTimeout(() => {
          redirect('/login')
        }, 3000)
      } catch (e) {
        notify(errorMessageHandler(e), 'error', {}, false, 100000)
      }
    },
    [email, api, notify, redirect]
  )

  return (
    <BaseLogin
      // A random image that changes everyday
      backgroundImage={loginBck}
    >
      <CssBaseline />
      <form onSubmit={submit}>
        <InputsWrapper>
          <div>
            <TextField
              id="email"
              label="Your email address"
              type="email"
              style={{ width: '100%' }}
              value={email}
              required
              onChange={(event) => setEmail(event.target.value)}
              size="small"
            />
          </div>
        </InputsWrapper>

        <SubmitWrapper>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Reset Password
          </Button>
        </SubmitWrapper>
      </form>

      <Notification />
    </BaseLogin>
  )
}), 'Reset Password')
