import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Button } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog } from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const Loader = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99999;
  cursor: wait;

  & svg {
    color: lightgray !important;
  }
`;

const getCroppedImgBlob = (image, crop, fileName) => {
  const canvas = document.createElement("canvas");
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");
  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;

        resolve(blob);
      },
      "image/jpeg",
      1
    );
  });
};

const initialCrop = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: "%",
};

export const CropDialog = ({ open, onClose, fileToCrop, onSaveCrop }) => {
  const [crop, setCrop] = useState(initialCrop);
  const [croppedImageBlob, setCroppedImageBlob] = useState();
  const [loading, setLoading] = useState(false);
  const imageRef = useRef();

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setCrop(initialCrop);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
    >
      {loading && (
        <Loader>
          <CircularProgress color={"primary"} />
        </Loader>
      )}
      <ReactCrop
        style={{ opacity: loading ? 0.3 : 1 }}
        maxHeight={2000}
        maxWidth={2000}
        crop={crop}
        onChange={setCrop}
        onComplete={async (crop) => {
          if (imageRef.current && crop.width && crop.height) {
            getCroppedImgBlob(imageRef.current, crop, "img.jpeg").then(
              setCroppedImageBlob
            );
          }
        }}
        keepSelection
      >
        <img
          style={{ maxHeight: "70vh", width: "100%" }}
          src={fileToCrop}
          alt="base"
          ref={imageRef}
          crossOrigin="anonymous"
        />
      </ReactCrop>

      <span style={{ margin: "10px auto" }}>
        You can crop the image or click close to keep the image as is
      </span>

      <div style={{ display: "flex", gap: "20px", margin: "20px auto" }}>
        <Button label="Close" onClick={onClose} variant="outlined" />
        <Button
          label="Save"
          disabled={!croppedImageBlob}
          variant="contained"
          color="primary"
          onClick={(e) => {
            if (croppedImageBlob) {
              setLoading(true);
              const reader = new FileReader();

              reader.addEventListener("load", () => {
                const result = reader.result;
                let image = new Image();
                image.src = result;
                image.addEventListener("load", async () => {
                  await onSaveCrop(croppedImageBlob);
                  setLoading(false);
                  onClose();
                });
              });

              reader.readAsDataURL(croppedImageBlob);
            }
          }}
        />
      </div>
    </Dialog>
  );
};
